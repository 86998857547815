function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { commonStyles } from '../styles';
export var modalContainer = {
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
  overflow: 'hidden auto',
  display: 'block'
};
export var modalScrollContainer = function modalScrollContainer(_ref) {
  var large = _ref.large,
      small = _ref.small;
  return [{
    minHeight: 'calc(100vh - 3.5em)',
    maxWidth: 500,
    margin: '1.75em auto',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center'
  }, large && {
    '@media (min-width: 992px)': {
      maxWidth: 800
    }
  }, small && {
    '@media (min-width: 576px)': {
      maxWidth: 300
    }
  }];
};
export var modalContentContainer = function modalContentContainer(props) {
  return function (theme) {
    return [{
      display: 'flex',
      flexFlow: 'column nowrap',
      justifyContent: 'flex-start',
      width: '100%',
      position: 'relative',
      backgroundColor: theme.white,
      border: "1px solid ".concat(theme.gray300),
      padding: '2em',
      borderRadius: 8
    }].concat(_toConsumableArray(commonStyles(props)));
  };
};