function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useEffect, useState } from 'react';
export var useCollapse = function useCollapse() {
  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      nodeRef = _useState4[0],
      setNodeRef = _useState4[1];

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      nodeRefStyle = _useState6[0],
      setNodeRefStyle = _useState6[1];

  var displayNoneStyle = {
    display: 'none',
    height: 0
  };

  var collapsingStyles = function collapsingStyles(height) {
    return {
      display: '',
      height: "".concat(height, "px"),
      overflow: 'hidden',
      transition: 'height 0.4s ease-in-out'
    };
  };

  useEffect(function () {
    nodeRef === null || nodeRef === void 0 ? void 0 : nodeRef.addEventListener('transitionend', handleTransitionEnd);
    nodeRef && isOpen && addStyles();
    return function () {
      nodeRef === null || nodeRef === void 0 ? void 0 : nodeRef.removeEventListener('transitionend', handleTransitionEnd);
    };
  }, [nodeRef]);

  var addStyles = function addStyles() {
    setNodeRefStyle(collapsingStyles(isOpen ? nodeRef.scrollHeight : 0));
  };

  var handleTransitionEnd = function handleTransitionEnd() {
    return setNodeRefStyle(null);
  };

  var toggle = function toggle() {
    if (isOpen) return addStyles();
    setNodeRefStyle(displayNoneStyle);
    setIsOpen(true);
  };

  return {
    toggle: toggle,
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    nodeEl: setNodeRef,
    style: nodeRefStyle
  };
};