import _isFunction from "lodash/isFunction";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { concatStrings } from '../utils';
import { baseIcon } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var Icon = function Icon(props) {
  var onClick = props.onClick,
      iconName = props.iconName,
      className = props.className,
      linkTo = props.linkTo;

  var iconCore = ___EmotionJSX("i", _extends({
    role: "presentation",
    className: concatStrings(iconName, !linkTo && className),
    css: baseIcon(props)
  }, _isFunction(onClick) && {
    onClick: onClick,
    tabIndex: 0,
    role: 'button'
  }));

  return linkTo ? ___EmotionJSX(Link, _extends({
    to: linkTo
  }, className && {
    className: className
  }), iconCore) : iconCore;
};

Icon.propTypes = {
  onClick: PropTypes.func,
  iconName: PropTypes.string,
  className: PropTypes.string,
  linkTo: PropTypes.oneOfType([PropTypes.string, PropTypes.object])
};
export default Icon;