import _startCase from "lodash/startCase";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import PropTypes from 'prop-types';
import { colorStatus } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var ColorBatch = function ColorBatch(props) {
  var children = props.children,
      className = props.className;
  return ___EmotionJSX("span", _extends({
    css: colorStatus(props)
  }, className && {
    className: className
  }), _startCase(children));
};

ColorBatch.propTypes = {
  children: PropTypes.any,
  type: PropTypes.string,
  className: PropTypes.string
};
export default ColorBatch;