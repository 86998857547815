import _isNil from "lodash/isNil";
import _omitBy from "lodash/omitBy";
import _isPlainObject from "lodash/isPlainObject";
import _mapValues from "lodash/mapValues";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { useHistory } from 'react-router-dom';
import qs from 'query-string';
import moment from 'moment';
import { isJsonString, parseJson } from '../utils';
var dateTimeFormat = 'MM-DD-YYYY';
export var useUrlParams = function useUrlParams() {
  var history = useHistory();
  var _history$location = history.location,
      pathname = _history$location.pathname,
      search = _history$location.search,
      state = _history$location.state;

  var urlQueryParams = _mapValues(qs.parse(search, {
    arrayFormat: 'index'
  }), function (el) {
    switch (true) {
      case isJsonString(el):
        return parseJson(el);

      case (/\d-\d-\d/g.test(el) || /\d\/\d\/\d/g.test(el)) && moment(el, dateTimeFormat).isValid():
        return moment(el, dateTimeFormat);

      default:
        return el;
    }
  });

  var setUrlQueryParams = function setUrlQueryParams(params) {
    var mappedParams = _mapValues(_objectSpread(_objectSpread({}, urlQueryParams), params), function (el) {
      switch (true) {
        case _isPlainObject(el):
          return JSON.stringify(el);

        case moment.isMoment(el):
          return el.format(dateTimeFormat);

        case moment.isDate(el):
          return moment(el).format(dateTimeFormat);

        default:
          return el;
      }
    });

    var filteredUrlParams = qs.stringify(_omitBy(mappedParams, _isNil), {
      arrayFormat: 'index'
    });
    history.replace({
      to: pathname,
      state: state,
      search: filteredUrlParams ? "?".concat(filteredUrlParams) : search
    });
  };

  return {
    urlQueryParams: urlQueryParams,
    setUrlQueryParams: setUrlQueryParams
  };
};