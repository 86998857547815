import _isFunction from "lodash/isFunction";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as infoIcon } from './assets/info.svg';
import { ReactComponent as successIcon } from './assets/success.svg';
import { ReactComponent as warningIcon } from './assets/warning.svg';
import { ReactComponent as errorIcon } from './assets/error.svg';
import Timer from './timer';
import { notification, notifContainer, notificationIcon } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";
var getIcon = {
  success: successIcon,
  error: errorIcon,
  warning: warningIcon,
  info: infoIcon
};

var Notification = function Notification(props) {
  var _getIcon$type;

  var id = props.id,
      onRemoval = props.onRemoval,
      title = props.title,
      message = props.message,
      content = props.content,
      _props$duration = props.duration,
      duration = _props$duration === void 0 ? 7000 : _props$duration,
      toggleRemoval = props.toggleRemoval,
      onClick = props.onClick,
      type = props.type,
      icon = props.icon,
      _props$alwaysShow = props.alwaysShow,
      alwaysShow = _props$alwaysShow === void 0 ? false : _props$alwaysShow,
      _props$pauseOnHover = props.pauseOnHover,
      pauseOnHover = _props$pauseOnHover === void 0 ? true : _props$pauseOnHover,
      className = props.className;
  var timerRef = useRef();
  var callbackRef = useRef(onRemoval);

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      hide = _useState2[0],
      setHide = _useState2[1];

  var onTransitionStart = function onTransitionStart() {
    if (!duration || alwaysShow) return;
    var notifTimer = new Timer(function () {
      return setHide(true);
    }, duration);
    timerRef.current = notifTimer;
    notifTimer.start();
  };

  useEffect(function () {
    pauseOnHover && onTransitionStart();
    return function () {
      var _timerRef$current;

      pauseOnHover && ((_timerRef$current = timerRef.current) === null || _timerRef$current === void 0 ? void 0 : _timerRef$current.clear());
    };
  }, []);

  var onTransitionEnd = function onTransitionEnd() {
    toggleRemoval(id);
    _isFunction(callbackRef) && callbackRef(props);
  };

  var onMouseEnter = function onMouseEnter() {
    var _timerRef$current2;

    return (_timerRef$current2 = timerRef.current) === null || _timerRef$current2 === void 0 ? void 0 : _timerRef$current2.pause();
  };

  var onMouseLeave = function onMouseLeave() {
    var _timerRef$current3;

    return (_timerRef$current3 = timerRef.current) === null || _timerRef$current3 === void 0 ? void 0 : _timerRef$current3.start();
  };

  var handleClick = function handleClick() {
    callbackRef.current = _isFunction(onClick) ? onClick : onRemoval;
    setHide(true);
  };

  var NotificationIcon = (_getIcon$type = getIcon[type]) !== null && _getIcon$type !== void 0 ? _getIcon$type : successIcon;
  return ___EmotionJSX("div", _extends({
    role: "presentation",
    css: notification(type, hide, props)
  }, className && {
    className: className
  }, {
    onMouseEnter: pauseOnHover ? onMouseEnter : null,
    onMouseLeave: pauseOnHover ? onMouseLeave : null,
    onClick: handleClick,
    onTransitionEnd: hide ? onTransitionEnd : onTransitionStart
  }), content !== null && content !== void 0 ? content : ___EmotionJSX("div", {
    css: notifContainer(type)
  }, icon !== null && icon !== void 0 ? icon : ___EmotionJSX(NotificationIcon, {
    css: notificationIcon
  }), ___EmotionJSX("span", null, title && ___EmotionJSX("h4", null, title), message && ___EmotionJSX("span", null, message))));
};

Notification.propTypes = {
  id: PropTypes.string,
  onRemoval: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string,
  content: PropTypes.node,
  duration: PropTypes.number,
  type: PropTypes.string,
  toggleRemoval: PropTypes.func,
  onClick: PropTypes.func,
  alwaysShow: PropTypes.bool,
  icon: PropTypes.any,
  pauseOnHover: PropTypes.bool,
  className: PropTypes.string
};
export default Notification;