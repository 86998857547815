import _omit from "lodash/omit";
import _isObject from "lodash/isObject";
import _isFunction from "lodash/isFunction";
var _excluded = ["value", "onChange", "onError", "validate", "disabled", "required", "isTouched", "componentRef", "dateFormat"];

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import { useState, useEffect, useImperativeHandle } from 'react';
import validateInput from '../inputValidation';
export var useDateTimePicker = function useDateTimePicker(_ref) {
  var initValue = _ref.value,
      onChange = _ref.onChange,
      onError = _ref.onError,
      validate = _ref.validate,
      disabled = _ref.disabled,
      required = _ref.required,
      isTouchedInit = _ref.isTouched,
      componentRef = _ref.componentRef,
      dateFormat = _ref.dateFormat,
      restProps = _objectWithoutProperties(_ref, _excluded);

  var strVal = initValue !== null && initValue !== void 0 && initValue.toString ? initValue.toString() : initValue !== null && initValue !== void 0 ? initValue : '';

  var _useState = useState(strVal),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      error = _useState4[0],
      setError = _useState4[1];

  var _useState5 = useState(isTouchedInit),
      _useState6 = _slicedToArray(_useState5, 2),
      isTouched = _useState6[0],
      setIsTouched = _useState6[1];

  useEffect(function () {
    setValue(strVal);
  }, [strVal]);
  useEffect(function () {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);
  useEffect(function () {
    isTouched && checkForError(value);
  }, [isTouched]);
  useImperativeHandle(componentRef, function () {
    return {
      value: value,
      changeValue: function changeValue(newValue) {
        return handleChange({
          target: {
            value: newValue
          }
        });
      },
      checkForError: checkForError,
      setError: handleError,
      changeIsTouched: setIsTouched
    };
  });

  var handleChange = function handleChange(newValue) {
    if (!_isObject(newValue)) return;
    setValue(newValue === null || newValue === void 0 ? void 0 : newValue.format(dateFormat));
    checkForError(newValue);
    _isFunction(onChange) && onChange(newValue);
  };

  var handleBlur = function handleBlur() {
    !isTouched && setIsTouched(true);
    checkForError(value);
  };

  var checkForError = function checkForError(newValue) {
    var newError = null;
    if (_isFunction(validate)) newError = validate(newValue);else if (required) newError = validateInput('required')(newValue);
    handleError(newError);
  };

  var handleError = function handleError(newError) {
    isTouched && setError(newError);
    _isFunction(onError) && onError(newError);
  };

  return {
    // Input props
    value: value,
    onChange: handleChange,
    onBlur: handleBlur,
    disabled: disabled,
    // Form related props
    error: error,
    isTouched: isTouched,
    restProps: _omit(restProps, ['className', 'label', 'horizontal', 'inputRef', 'formId'])
  };
};