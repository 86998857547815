function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useRef, useState, useEffect } from 'react';
import { useIntersectionObserver } from '../../hooks';
import { DEFAULT_DISPLAY_KEY, DEFAULT_UNIQUE_KEY } from './utils';
export default (function (_ref) {
  var _searchInputNodeRef$c, _searchInputNodeRef$c2, _searchResultsRef$cur, _ref2;

  var isOpen = _ref.isOpen,
      initOptions = _ref.options,
      _ref$displayKey = _ref.displayKey,
      displayKey = _ref$displayKey === void 0 ? DEFAULT_DISPLAY_KEY : _ref$displayKey,
      _ref$uniqueKey = _ref.uniqueKey,
      uniqueKey = _ref$uniqueKey === void 0 ? DEFAULT_UNIQUE_KEY : _ref$uniqueKey,
      optionsListRef = _ref.optionsListRef;
  var searchInputNodeRef = useRef(null);
  var searchResultsRef = useRef([]);
  var optionsScrollContainerRef = useRef(null);
  var optionsLoadingNodeRef = useRef(null);

  var _useState = useState([]),
      _useState2 = _slicedToArray(_useState, 2),
      filteredResults = _useState2[0],
      setFilteredResults = _useState2[1];

  var _useState3 = useState([]),
      _useState4 = _slicedToArray(_useState3, 2),
      scrollItems = _useState4[0],
      setScrollItems = _useState4[1];

  var hasSearch = (_searchInputNodeRef$c = searchInputNodeRef.current) === null || _searchInputNodeRef$c === void 0 ? void 0 : (_searchInputNodeRef$c2 = _searchInputNodeRef$c.value) === null || _searchInputNodeRef$c2 === void 0 ? void 0 : _searchInputNodeRef$c2.length;
  var scrollSize = 20;
  var needInfiniteScroll = (hasSearch ? (_searchResultsRef$cur = searchResultsRef.current) === null || _searchResultsRef$cur === void 0 ? void 0 : _searchResultsRef$cur.length : initOptions === null || initOptions === void 0 ? void 0 : initOptions.length) > scrollSize;
  var allOptions = hasSearch ? filteredResults : needInfiniteScroll ? scrollItems : initOptions;
  var renderedOptionsLength = (_ref2 = hasSearch ? searchResultsRef.current : initOptions) === null || _ref2 === void 0 ? void 0 : _ref2.length;
  var hasNextPage = needInfiniteScroll && !!(allOptions !== null && allOptions !== void 0 && allOptions.length) && renderedOptionsLength > (allOptions === null || allOptions === void 0 ? void 0 : allOptions.length);
  useEffect(function () {
    var _searchInputNodeRef$c3;

    isOpen && ((_searchInputNodeRef$c3 = searchInputNodeRef.current) === null || _searchInputNodeRef$c3 === void 0 ? void 0 : _searchInputNodeRef$c3.focus());
  }, [searchInputNodeRef.current]);
  useEffect(function () {
    needInfiniteScroll && setScrollItems(initOptions.slice(0, scrollSize));
  }, [initOptions === null || initOptions === void 0 ? void 0 : initOptions.length]);
  useIntersectionObserver(optionsLoadingNodeRef, optionsScrollContainerRef, function (entry) {
    return (entry === null || entry === void 0 ? void 0 : entry.isIntersecting) && hasNextPage && fetchMoreResults();
  });

  var compareValues = function compareValues(value, input) {
    return String(value).toLowerCase().trim().indexOf(input.toLowerCase().trim()) !== -1;
  };

  var filterValues = function filterValues(input) {
    return function (el) {
      var _el$groupLabel;

      return (_el$groupLabel = el.groupLabel) !== null && _el$groupLabel !== void 0 ? _el$groupLabel : compareValues(el[uniqueKey], input) || compareValues(el[displayKey], input);
    };
  };

  var filterResults = function filterResults(input) {
    // eslint-disable-next-line no-param-reassign
    if (optionsListRef) optionsListRef.scrollTop = 0;
    var results = initOptions.filter(filterValues(input)).filter(function (el, i, arr) {
      var _arr2;

      return !(el !== null && el !== void 0 && el.groupLabel) || arr[i + 1] && !((_arr2 = arr[i + 1]) !== null && _arr2 !== void 0 && _arr2.groupLabel);
    });
    searchResultsRef.current = results;
    return results.length ? setFilteredResults(results.slice(0, scrollSize)) : setFilteredResults(null);
  };

  var fetchMoreResults = function fetchMoreResults() {
    return !hasSearch ? setScrollItems(function (prev) {
      return prev.concat(initOptions.slice(prev === null || prev === void 0 ? void 0 : prev.length, (prev === null || prev === void 0 ? void 0 : prev.length) + scrollSize));
    }) : setFilteredResults(function (prev) {
      var _searchResultsRef$cur2;

      return prev.concat((_searchResultsRef$cur2 = searchResultsRef.current) === null || _searchResultsRef$cur2 === void 0 ? void 0 : _searchResultsRef$cur2.slice(prev === null || prev === void 0 ? void 0 : prev.length, (prev === null || prev === void 0 ? void 0 : prev.length) + scrollSize));
    });
  };

  return {
    allOptions: allOptions,
    filterResults: filterResults,
    searchInputNodeRef: searchInputNodeRef,
    optionsScrollContainerRef: optionsScrollContainerRef,
    optionsLoadingNodeRef: optionsLoadingNodeRef,
    fetchMoreResults: fetchMoreResults,
    hasNextPage: hasNextPage
  };
});