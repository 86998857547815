export var inputField = function inputField(hasValidation, hasError, _ref) {
  var disabled = _ref.disabled,
      small = _ref.small,
      leftIcon = _ref.leftIcon;
  return function (theme) {
    var _leftIcon$size;

    return [{
      color: theme.gray700,
      border: "1px solid ".concat(hasError ? theme.error : theme.gray200),
      backgroundColor: hasError ? theme.errorLight : theme.white,
      borderRadius: '0.3rem',
      minHeight: small ? '3.6rem' : '4.4rem',
      padding: small ? '0.35em 0.85em' : '0.7em 0.85em',
      width: '100%',
      marginBottom: hasValidation ? 6 : 0,
      outline: 'none'
    }, hasError && {
      '&::placeholder': {
        color: theme.gray500
      }
    }, leftIcon && {
      paddingLeft: "".concat(((_leftIcon$size = leftIcon === null || leftIcon === void 0 ? void 0 : leftIcon.size) !== null && _leftIcon$size !== void 0 ? _leftIcon$size : 2) + 0.6, "em")
    }, disabled ? {
      borderColor: theme.gray150,
      color: theme.gray150,
      cursor: 'not-allowed',
      '&::placeholder': {
        color: theme.gray150
      }
    } : {
      '&:focus': {
        borderColor: theme.highlight,
        outline: 'none',
        '&::placeholder': {
          color: theme.gray500
        }
      }
    }];
  };
};