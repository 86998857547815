import _isFunction from "lodash/isFunction";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { radioButtonContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var CheckButton = function CheckButton(props) {
  var children = props.children,
      value = props.value,
      onChange = props.onChange,
      className = props.className;

  var _useState = useState(!!value),
      _useState2 = _slicedToArray(_useState, 2),
      isChecked = _useState2[0],
      setIsChecked = _useState2[1];

  useEffect(function () {
    setIsChecked(value);
  }, [value]);

  var handleChange = function handleChange() {
    setIsChecked(function (prev) {
      _isFunction(onChange) && onChange(!prev);
      return !prev;
    });
  };

  return ___EmotionJSX("div", _extends({
    role: "checkbox",
    tabIndex: 0,
    css: radioButtonContainer(isChecked, !!children, props)
  }, className && {
    className: className
  }, {
    onClick: handleChange,
    "aria-checked": isChecked
  }), ___EmotionJSX("svg", {
    className: "input-indicator",
    viewBox: "0 0 40 40"
  }, ___EmotionJSX("circle", {
    id: "border",
    r: "20",
    cx: "50%",
    cy: "50%"
  }), ___EmotionJSX("circle", {
    id: "dot",
    r: "10",
    cx: "50%",
    cy: "50%"
  })), ___EmotionJSX("span", {
    className: "label"
  }, children));
};

CheckButton.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.any
};
export default CheckButton;