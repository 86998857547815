function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { container, linkItem, separator, text } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var Breadcrumbs = function Breadcrumbs(props) {
  var items = props.items,
      className = props.className;
  if (!items.every(function (el) {
    return el.label;
  })) return null;
  return ___EmotionJSX("ul", _extends({
    css: container(props)
  }, className && {
    className: className
  }), items.map(function (el, index, arr) {
    var isNotLastElement = index !== arr.length - 1;
    return ___EmotionJSX("li", {
      key: index
    }, el.url && isNotLastElement ? ___EmotionJSX(Link, {
      to: el.url,
      css: linkItem(isNotLastElement)
    }, el.label) : ___EmotionJSX("span", {
      css: text
    }, el.label), isNotLastElement ? ___EmotionJSX("span", {
      css: separator
    }, "/") : '');
  }));
};

Breadcrumbs.propTypes = {
  items: PropTypes.array,
  className: PropTypes.string
};
export default Breadcrumbs;