import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom';
import BaseApp from './screens/App';
import { Theme, Notifications } from './components';
import initGlobalStyles from './assets/css/globalCSS';
import { jsx as ___EmotionJSX } from "@emotion/react";

var MainApp = function MainApp() {
  return ___EmotionJSX(StrictMode, null, ___EmotionJSX(Theme, {
    initGlobalStyles: initGlobalStyles
  }, ___EmotionJSX(Router, null, ___EmotionJSX(Notifications, null), ___EmotionJSX(BaseApp, null))));
};

var root = createRoot(document.getElementById('seats-portal-root'));
root.render(___EmotionJSX(MainApp, null));