import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { jsx as ___EmotionJSX } from "@emotion/react";

var App = function App() {
  return ___EmotionJSX(Switch, null, ___EmotionJSX(Route, {
    component: function component() {
      return ___EmotionJSX("h1", null, "Hello Seats Portal");
    }
  }));
};

export default App;