import { useEffect, useRef } from 'react';
export var useIsMounted = function useIsMounted() {
  var isMountedRef = useRef();
  useEffect(function () {
    isMountedRef.current = true;
    return function () {
      isMountedRef.current = false;
    };
  }, []);
  return isMountedRef;
};