import _isFunction from "lodash/isFunction";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { checkButtonContainer, checkButtonIndicator, checkButtonSvg } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var CheckButton = function CheckButton(props) {
  var children = props.children,
      onError = props.onError,
      value = props.value,
      disabled = props.disabled,
      onChange = props.onChange,
      className = props.className,
      inverse = props.inverse,
      required = props.required,
      isTouched = props.isTouched;

  var _useState = useState(!!value),
      _useState2 = _slicedToArray(_useState, 2),
      isChecked = _useState2[0],
      setIsChecked = _useState2[1];

  var _useState3 = useState(false),
      _useState4 = _slicedToArray(_useState3, 2),
      error = _useState4[0],
      setError = _useState4[1];

  useEffect(function () {
    setIsChecked(!!value);
  }, [!!value]);
  useEffect(function () {
    isTouched && checkForError(isChecked);
  }, [isTouched]);

  var handleChange = function handleChange() {
    if (disabled) return;
    setIsChecked(function (prev) {
      _isFunction(onChange) && onChange(!prev);
      checkForError(!prev);
      return !prev;
    });
  };

  var checkForError = function checkForError(value) {
    if (required && !value && _isFunction(onError)) {
      onError({
        msg: 'Required'
      });
      setError(true);
    } else setError(false);
  };

  return ___EmotionJSX("div", _extends({
    role: "checkbox",
    tabIndex: 0,
    css: checkButtonContainer(!!children, props)
  }, className && {
    className: className
  }, {
    onClick: handleChange,
    "aria-checked": isChecked
  }), ___EmotionJSX("span", {
    css: checkButtonIndicator(isChecked, props),
    className: "input-indicator"
  }, ___EmotionJSX("svg", {
    css: checkButtonSvg(isChecked, props),
    viewBox: "0 0 21 21"
  }, inverse ? ___EmotionJSX("path", {
    d: "M5,10.75 L8.5,14.25 L19.4,2.3 C18.8333333,1.43333333 18.0333333,1 17,1 L4,1 C2.35,1 1,2.35 1,4 L1,17 C1,18.65 2.35,20 4,20 L17,20 C18.65,20 20,18.65 20,17 L20,7.99769186"
  }) : ___EmotionJSX("polyline", {
    points: "5 10.75 8.5 14.25 16 6"
  }))), ___EmotionJSX("span", {
    className: "label"
  }, children), error && ___EmotionJSX("span", {
    className: "error"
  }, "Required!"));
};

CheckButton.displayName = 'CheckBox';
CheckButton.propTypes = {
  value: PropTypes.bool,
  disabled: PropTypes.bool,
  isTouched: PropTypes.bool,
  required: PropTypes.bool,
  onError: PropTypes.func,
  hasError: PropTypes.bool,
  onChange: PropTypes.func,
  className: PropTypes.string,
  inverse: PropTypes.bool,
  children: PropTypes.any
};
export default CheckButton;