import _isEmpty from "lodash/isEmpty";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import PropTypes from 'prop-types';
import ColorBatch from '../../ColorBatch';
import { Icon } from '../../Icon';
import { dropDownSelect, dropDownRightIcon, dropDownText, rightIconsContainer, dropDownDeleteIcon, dropDownItemsCountBadge } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var DropdownSelect = function DropdownSelect(_ref) {
  var leftIcon = _ref.leftIcon,
      rightIcon = _ref.rightIcon,
      _ref$small = _ref.small,
      small = _ref$small === void 0 ? false : _ref$small,
      _ref$multiSelect = _ref.multiSelect,
      multiSelect = _ref$multiSelect === void 0 ? false : _ref$multiSelect,
      _ref$noClear = _ref.noClear,
      noClear = _ref$noClear === void 0 ? false : _ref$noClear,
      _ref$noItemsBadge = _ref.noItemsBadge,
      noItemsBadge = _ref$noItemsBadge === void 0 ? false : _ref$noItemsBadge,
      selected = _ref.selected,
      handleSelect = _ref.handleSelect,
      isOpen = _ref.isOpen,
      isDisabled = _ref.isDisabled,
      displayValue = _ref.displayValue,
      hasError = _ref.hasError;

  var clearIcon = !noClear && ___EmotionJSX(Icon, {
    iconName: "la la-times",
    color: "black",
    css: dropDownDeleteIcon,
    onClick: function onClick(e) {
      e.stopPropagation();
      handleSelect(null);
    }
  });

  var itemsBadge = !noItemsBadge && ___EmotionJSX(ColorBatch, {
    css: dropDownItemsCountBadge(false)
  }, selected === null || selected === void 0 ? void 0 : selected.length);

  return ___EmotionJSX("div", {
    css: dropDownSelect(isOpen, isDisabled, hasError, small)
  }, leftIcon !== null && leftIcon !== void 0 && leftIcon.iconName ? ___EmotionJSX(Icon, leftIcon) : leftIcon, ___EmotionJSX("span", {
    css: dropDownText
  }, displayValue), ___EmotionJSX("div", {
    css: rightIconsContainer
  }, !_isEmpty(selected) && (multiSelect ? itemsBadge : clearIcon), ___EmotionJSX(Icon, _extends({
    iconName: "la la-angle-down",
    color: "black",
    css: dropDownRightIcon(isOpen)
  }, rightIcon))));
};

DropdownSelect.propTypes = {
  leftIcon: PropTypes.object,
  rightIcon: PropTypes.object,
  small: PropTypes.bool,
  multiSelect: PropTypes.bool,
  noClear: PropTypes.bool,
  noItemsBadge: PropTypes.bool,
  selected: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  handleSelect: PropTypes.func,
  isOpen: PropTypes.bool,
  isDisabled: PropTypes.bool,
  hasError: PropTypes.bool,
  displayValue: PropTypes.any
};
export default DropdownSelect;