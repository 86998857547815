import _isEmpty from "lodash/isEmpty";
import _isPlainObject from "lodash/isPlainObject";
import _isNil from "lodash/isNil";
import _isEqual from "lodash/isEqual";
import _isString from "lodash/isString";
import _isArray from "lodash/isArray";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { Children } from 'react';
import { isStrNum } from '../utils';
import validateInput from '../Inputs/inputValidation';
export var getFormInputs = function getFormInputs(children) {
  var newChildrens = [];

  var getFlatList = function getFlatList(childs) {
    Children.forEach(childs, function (child) {
      var _child$props, _child$props2, _child$props3;

      var childChildren = Children.toArray(child === null || child === void 0 ? void 0 : (_child$props = child.props) === null || _child$props === void 0 ? void 0 : _child$props.children);
      childChildren.length ? getFlatList(child === null || child === void 0 ? void 0 : (_child$props2 = child.props) === null || _child$props2 === void 0 ? void 0 : _child$props2.children) : (child === null || child === void 0 ? void 0 : (_child$props3 = child.props) === null || _child$props3 === void 0 ? void 0 : _child$props3.formId) && newChildrens.push(child);
    });
  };

  getFlatList(children);
  return newChildrens;
}; // Check if the values are defined because lodash isEqual with 2 undefined returns true

var compareValues = function compareValues(source, value, keys) {
  // Compare string with object if they are related to the same thing
  // Example: code as a string and object with code property if the codes are same they are equal
  if (isStrNum(source) && _isPlainObject(value)) return keys.some(function (key) {
    return source === value[key];
  });
  if (_isPlainObject(source) && isStrNum(value)) return keys.some(function (key) {
    return value === source[key];
  }); // Compare two objects, if they have match

  var areDef = function areDef(key) {
    return !_isNil(source[key]) && !_isNil(value[key]);
  };

  var areSame = function areSame(key) {
    return _isEqual(source[key], value[key]);
  };

  var compare = function compare(key) {
    return areDef(key) && areSame(key);
  };

  if (_isPlainObject(source) && _isPlainObject(value)) return keys.some(compare);

  var hasSort = function hasSort(item) {
    return _isArray(item) ? _toConsumableArray(item).sort() : item;
  };

  return _isEqual(hasSort(source), hasSort(value));
};

var noValue = function noValue(val) {
  return _isNil(val) || (_isString(val) || _isArray(val) || _isPlainObject(val)) && _isEmpty(val);
};

export var areEqual = function areEqual(val, initValue, correspondChild) {
  if (_isNil(initValue) && noValue(val)) return true;
  if (!correspondChild) return null;
  var _correspondChild$prop = correspondChild.props,
      _correspondChild$prop2 = _correspondChild$prop.displayKey,
      displayKey = _correspondChild$prop2 === void 0 ? 'label' : _correspondChild$prop2,
      _correspondChild$prop3 = _correspondChild$prop.uniqueKey,
      uniqueKey = _correspondChild$prop3 === void 0 ? 'value' : _correspondChild$prop3;
  var keys = [displayKey, uniqueKey];
  return compareValues(initValue, val, keys);
};
export var requiredFieldValidation = function requiredFieldValidation(val, child) {
  var _child$type, _child$props$label, _child$props4, _child$props$label2, _child$props5;

  switch (child === null || child === void 0 ? void 0 : (_child$type = child.type) === null || _child$type === void 0 ? void 0 : _child$type.displayName) {
    case 'Input':
      return validateInput('required')(val);

    case 'FileInput':
      return validateInput('required')(child.props.multiple ? val.length ? val.length : '' : val === null || val === void 0 ? void 0 : val.data);

    case 'DateTimePicker':
      return validateInput('required')(val);

    case 'Dropdown':
      return isStrNum(val) ? validateInput('required')(val) : _isEmpty(val) && {
        msg: "".concat((_child$props$label = child === null || child === void 0 ? void 0 : (_child$props4 = child.props) === null || _child$props4 === void 0 ? void 0 : _child$props4.label) !== null && _child$props$label !== void 0 ? _child$props$label : 'Field', " is required!")
      };

    case 'PhoneInput':
      return isStrNum(val) ? validateInput('required')(val) : validateInput('required')(val === null || val === void 0 ? void 0 : val.input) || validateInput('required')(val === null || val === void 0 ? void 0 : val.phoneCode);

    case 'CheckBox':
      return !val && {
        msg: "".concat((_child$props$label2 = child === null || child === void 0 ? void 0 : (_child$props5 = child.props) === null || _child$props5 === void 0 ? void 0 : _child$props5.label) !== null && _child$props$label2 !== void 0 ? _child$props$label2 : 'Field', " is required!")
      };

    default:
      return null;
  }
};