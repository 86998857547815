var _excluded = ["count", "duration", "height", "width", "circle"];

var _templateObject;

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { keyframes } from '@emotion/react';
var defaultBaseColor = '#eee';
var defaultHighlightColor = '#f5f5f5';
var skeletonKeyframes = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    background-position: -200px 0;\n  }\n  100% {\n    background-position: calc(200px + 100%) 0;\n  }\n"])));
export var skeletonClass = function skeletonClass(_ref) {
  var count = _ref.count,
      duration = _ref.duration,
      height = _ref.height,
      width = _ref.width,
      circle = _ref.circle,
      restStyles = _objectWithoutProperties(_ref, _excluded);

  return [{
    display: 'block',
    backgroundColor: defaultBaseColor,
    backgroundImage: "linear-gradient(\n        90deg,\n      ".concat(defaultBaseColor, ",\n      ").concat(defaultHighlightColor, ",\n      ").concat(defaultBaseColor, "\n      )"),
    backgroundSize: '200px 100%',
    backgroundRepeat: 'no-repeat',
    borderRadius: 4,
    lineHeight: 1,
    width: width || '100%',
    animation: "".concat(skeletonKeyframes, " ").concat(duration, "s ease-in-out infinite"),
    margin: count > 1 ? '8px 0' : 0
  }, height && {
    height: height
  }, width && height && circle && {
    borderRadius: '50%'
  }, _objectSpread({}, restStyles)];
};