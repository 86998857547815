var _templateObject, _templateObject2;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { keyframes } from '@emotion/react';
var circleAnimation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    stroke-dasharray: 1, 150;\n    stroke-dashoffset: 0;\n  }\n  50% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -35;\n  }\n  100% {\n    stroke-dasharray: 90, 150;\n    stroke-dashoffset: -124;\n  }\n"])));
var svgAnimation = keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  100% {\n    transform: rotate(360deg);\n  }\n"])));
export var loader = function loader(size, _ref) {
  var color = _ref.color,
      fullScreen = _ref.fullScreen;
  return function (theme) {
    return [{
      width: size,
      height: size,
      position: 'absolute',
      top: "calc(50% - ".concat(size / 2, "px)"),
      left: "calc(50% - ".concat(size / 2, "px)"),
      zIndex: 1,
      animation: "".concat(svgAnimation, " 2s linear infinite"),
      '& .path': {
        stroke: theme.primary,
        strokeLinecap: 'round',
        strokeWidth: '8px',
        animation: "".concat(circleAnimation, " 1.5s ease-in-out infinite")
      }
    }, fullScreen && {
      position: 'fixed'
    }, color && {
      '& .path': {
        stroke: theme[color]
      }
    }];
  };
};