import _isFunction from "lodash/isFunction";

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useState, useEffect, useImperativeHandle } from 'react';
import inputValidation from '../inputValidation';
export var useInput = function useInput(_ref) {
  var _ref$value = _ref.value,
      initValue = _ref$value === void 0 ? '' : _ref$value,
      onChange = _ref.onChange,
      onError = _ref.onError,
      onBlur = _ref.onBlur,
      validate = _ref.validate,
      pattern = _ref.pattern,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      _ref$required = _ref.required,
      required = _ref$required === void 0 ? false : _ref$required,
      _ref$isTouched = _ref.isTouched,
      isTouchedInit = _ref$isTouched === void 0 ? false : _ref$isTouched,
      componentRef = _ref.componentRef,
      _ref$number = _ref.number,
      number = _ref$number === void 0 ? false : _ref$number;
  var strVal = initValue !== null && initValue !== void 0 && initValue.toString ? initValue.toString() : initValue !== null && initValue !== void 0 ? initValue : '';

  var _useState = useState(strVal),
      _useState2 = _slicedToArray(_useState, 2),
      value = _useState2[0],
      setValue = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      error = _useState4[0],
      setError = _useState4[1];

  var _useState5 = useState(isTouchedInit !== null && isTouchedInit !== void 0 ? isTouchedInit : false),
      _useState6 = _slicedToArray(_useState5, 2),
      isTouched = _useState6[0],
      setIsTouched = _useState6[1];

  var getValue = function getValue(val) {
    return number && val ? Number(val) : val;
  };

  useEffect(function () {
    setValue(strVal);
  }, [strVal]);
  useEffect(function () {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);
  useEffect(function () {
    isTouched && checkForError(value);
  }, [isTouched]);
  useImperativeHandle(componentRef, function () {
    return {
      value: value,
      changeValue: function changeValue(newValue) {
        return handleChange({
          target: {
            value: newValue
          }
        });
      },
      checkForError: checkForError,
      changeError: setError,
      changeIsTouched: setIsTouched
    };
  });

  var handleChange = function handleChange(event) {
    var target = event.target;
    var newValue = target.value;
    if (newValue.length && _isFunction(pattern) && !pattern(newValue.slice(-1))) return;
    setValue(newValue);
    checkForError(newValue);
    _isFunction(onChange) && onChange(getValue(newValue));
  }; // On blur is used only to change the flag for touched


  var handleBlur = function handleBlur() {
    !isTouched && setIsTouched(true);
    checkForError(value);
    _isFunction(onBlur) && onBlur();
  };

  var checkForError = function checkForError(newValue) {
    var newError = null; // If validate function is provided check for error

    if (_isFunction(validate)) newError = validate(getValue(newValue));else if (required) newError = inputValidation('required')(getValue(newValue));
    setError(newError);
    _isFunction(onError) && onError(newError);
  };

  return {
    // Input props
    value: value,
    onChange: handleChange,
    onBlur: handleBlur,
    disabled: disabled,
    // Form related props
    error: error,
    isTouched: isTouched
  };
};