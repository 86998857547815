function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { Icon } from '../Icon';
import { flipInXAnimation } from '../styles';
import { collapseContainer, collapseHeader } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var Collapse = function Collapse(props) {
  var header = props.header,
      children = props.children,
      className = props.className;
  var childsRef = useRef();

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  return ___EmotionJSX("div", _extends({
    css: collapseContainer(props)
  }, className && {
    className: className
  }), ___EmotionJSX("div", {
    role: "button",
    tabIndex: 0,
    onClick: function onClick() {
      return setIsOpen(function (prev) {
        return !prev;
      });
    },
    className: "collapse-header",
    css: collapseHeader(isOpen)
  }, ___EmotionJSX("span", {
    className: "collapse-title"
  }, header), ___EmotionJSX(Icon, {
    size: 16,
    iconName: "la la-angle-down",
    color: "textLightSecondary"
  })), isOpen && ___EmotionJSX("div", {
    ref: childsRef,
    className: "collapse-content",
    css: flipInXAnimation()
  }, children));
};

Collapse.propTypes = {
  header: PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.number]),
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  className: PropTypes.string
};
export default Collapse;