function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { commonStyles } from '../styles';
export var backgroundPicture = function backgroundPicture(props) {
  var size = props.size,
      src = props.src,
      width = props.width,
      height = props.height,
      position = props.position,
      repeat = props.repeat;
  return [{
    backgroundImage: "url(".concat(src, ")"),
    backgroundSize: size || 'cover',
    backgroundPosition: position || 'center',
    backgroundRepeat: repeat || 'no-repeat',
    width: width || '100%',
    height: height || '100%'
  }].concat(_toConsumableArray(commonStyles(props)));
};
export var imageContainer = function imageContainer(_ref) {
  var size = _ref.size,
      position = _ref.position,
      className = _ref.className;
  return [{
    objectFit: size || 'cover',
    objectPosition: position || 'center',
    maxHeight: '100%',
    maxWidth: '100%'
  }, className];
};
export var child = function child(_ref2) {
  var fontSize = _ref2.fontSize,
      color = _ref2.color;
  return [{
    fontWeight: 'bold',
    fontSize: fontSize || 16
  }, color && {
    color: color
  }];
};