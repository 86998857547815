function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { commonStyles } from '../styles';
export var tooltipContainer = {
  position: 'relative',
  display: 'inline-block',
  textAlign: 'center',
  cursor: 'pointer',
  outline: 'none'
};
export var popupTooltip = function popupTooltip(props) {
  return function (theme) {
    var singleLine = props.singleLine,
        backgroundColor = props.backgroundColor,
        color = props.color,
        border = props.border,
        borderRadius = props.borderRadius;
    return [{
      padding: '1em 0.8em',
      backgroundColor: backgroundColor ? theme[backgroundColor] || backgroundColor : theme.gray700,
      color: color ? theme[color] || color : theme.gray50,
      whiteSpace: singleLine ? 'nowrap' : 'normal',
      boxShadow: theme.boxShadowLight,
      borderRadius: borderRadius || 8
    }, border && {
      border: border(theme)
    }].concat(_toConsumableArray(commonStyles(props)));
  };
};
export var arrow = function arrow(_ref) {
  var _ref$placement = _ref.placement,
      placement = _ref$placement === void 0 ? 'right' : _ref$placement;
  return [{
    position: 'absolute',
    background: 'inherit',
    '&::before': {
      content: "''",
      transform: 'rotate(45deg)',
      position: 'absolute',
      width: 8,
      height: 8,
      background: 'inherit'
    }
  }, placement.includes('top') && {
    bottom: 4
  }, placement.includes('bottom') && {
    top: -4
  }, placement.includes('left') && {
    right: 4
  }, placement.includes('right') && {
    left: -4
  }];
};