import _isFunction from "lodash/isFunction";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { usePopper } from 'react-popper';
import BodyOverflow from '../BodyOverflow';
import { tooltipContainer, popupTooltip, arrow } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var Tooltip = function Tooltip(props) {
  var children = props.children,
      content = props.content,
      onClick = props.onClick,
      open = props.open,
      className = props.className,
      _props$placement = props.placement,
      placement = _props$placement === void 0 ? 'right' : _props$placement,
      strategy = props.strategy,
      offset = props.offset,
      _props$noArrow = props.noArrow,
      noArrow = _props$noArrow === void 0 ? false : _props$noArrow,
      _props$closeOnClick = props.closeOnClick,
      closeOnClick = _props$closeOnClick === void 0 ? false : _props$closeOnClick,
      zIndex = props.zIndex,
      contentClassName = props.contentClassName,
      mainContainerProps = props.mainContainerProps;

  var _useState = useState(open),
      _useState2 = _slicedToArray(_useState, 2),
      isOpen = _useState2[0],
      setIsOpen = _useState2[1];

  var _useState3 = useState(null),
      _useState4 = _slicedToArray(_useState3, 2),
      referenceElement = _useState4[0],
      setReferenceElement = _useState4[1];

  var _useState5 = useState(null),
      _useState6 = _slicedToArray(_useState5, 2),
      popperElement = _useState6[0],
      setPopperElement = _useState6[1];

  var _useState7 = useState(null),
      _useState8 = _slicedToArray(_useState7, 2),
      arrowElement = _useState8[0],
      setArrowElement = _useState8[1];

  var _usePopper = usePopper(referenceElement, popperElement, {
    modifiers: [!noArrow && {
      name: 'arrow',
      options: {
        element: arrowElement
      }
    }, offset && {
      name: 'offset',
      options: {
        offset: offset
      }
    }].filter(Boolean),
    placement: placement,
    strategy: strategy
  }),
      styles = _usePopper.styles,
      attributes = _usePopper.attributes;

  useEffect(function () {
    setIsOpen(open);
  }, [open]);

  var handleClick = function handleClick(_ref) {
    var target = _ref.target;
    return (!(popperElement !== null && popperElement !== void 0 && popperElement.contains(target)) || closeOnClick) && onClick && setIsOpen(function (prev) {
      return !prev;
    });
  };

  var handleBlur = function handleBlur(_ref2) {
    var relatedTarget = _ref2.relatedTarget;
    return !(popperElement !== null && popperElement !== void 0 && popperElement.contains(relatedTarget)) && onClick && setIsOpen(false);
  };

  var handleMouseEnter = function handleMouseEnter() {
    return !onClick && setIsOpen(true);
  };

  var handleMouseLeave = function handleMouseLeave() {
    return !onClick && setIsOpen(false);
  };

  return ___EmotionJSX("div", _extends({
    ref: setReferenceElement,
    role: "button",
    tabIndex: 0,
    onClick: handleClick,
    onBlur: handleBlur,
    onMouseEnter: handleMouseEnter,
    onMouseLeave: handleMouseLeave,
    css: tooltipContainer
  }, className && {
    className: className
  }, mainContainerProps), isOpen && ___EmotionJSX(BodyOverflow, {
    fixed: true,
    className: "tooltip-body-overflow",
    zIndex: zIndex,
    css: contentClassName
  }, ___EmotionJSX("div", _extends({
    ref: setPopperElement,
    className: "tooltip-content",
    css: popupTooltip(props),
    style: styles.popper
  }, attributes.popper), _isFunction(content) ? content({
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    referenceElement: referenceElement,
    popperElement: popperElement
  }) : content, !noArrow && ___EmotionJSX("div", {
    ref: setArrowElement,
    style: styles.arrow,
    css: arrow(props),
    className: "tooltip-arrow"
  }))), children);
};

Tooltip.propTypes = {
  children: PropTypes.any,
  content: PropTypes.any,
  open: PropTypes.bool,
  onClick: PropTypes.bool,
  className: PropTypes.string,
  placement: PropTypes.string,
  strategy: PropTypes.string,
  offset: PropTypes.array,
  noArrow: PropTypes.bool,
  closeOnClick: PropTypes.bool,
  zIndex: PropTypes.number,
  contentClassName: PropTypes.any,
  mainContainerProps: PropTypes.object
};
export default Tooltip;