var _templateObject, _templateObject2, _templateObject3, _templateObject4, _templateObject5, _templateObject6, _templateObject7, _templateObject8, _templateObject9, _templateObject10, _templateObject11, _templateObject12, _templateObject13, _templateObject14, _templateObject15, _templateObject16, _templateObject17, _templateObject18, _templateObject19, _templateObject20, _templateObject21, _templateObject22, _templateObject23, _templateObject24, _templateObject25, _templateObject26, _templateObject27, _templateObject28, _templateObject29, _templateObject30, _templateObject31, _templateObject32, _templateObject33, _templateObject34, _templateObject35, _templateObject36, _templateObject37, _templateObject38, _templateObject39;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

// Docs can be found here https://animate.style/
import { keyframes } from '@emotion/react';
export var animationDuration = function animationDuration() {
  var duration = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return {
    animationDuration: "".concat(duration, "s"),
    transitionDuration: "".concat(duration, "s")
  };
};
export var animationDelay = function animationDelay() {
  var delay = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 0;
  return {
    animationDelay: "".concat(delay, "s")
  };
};
export var animationIterationCount = function animationIterationCount() {
  var count = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 1;
  return {
    animationIterationCount: count
  };
};

var baseAnimationStyle = function baseAnimationStyle() {
  var name = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'none';
  var params = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  return _objectSpread(_objectSpread(_objectSpread({
    animationName: name,
    animationFillMode: 'both'
  }, animationDelay(params.delay)), animationIterationCount(params.iterationCount)), animationDuration(params.duration));
};

var bounce = function bounce(_ref) {
  var _ref$translateStart = _ref.translateStart,
      translateStart = _ref$translateStart === void 0 ? '0, 0, 0' : _ref$translateStart,
      _ref$translate = _ref.translate40,
      translate40 = _ref$translate === void 0 ? '0, -30px, 0' : _ref$translate,
      _ref$translate2 = _ref.translate70,
      translate70 = _ref$translate2 === void 0 ? '0, -15px, 0' : _ref$translate2,
      _ref$translate3 = _ref.translate80,
      translate80 = _ref$translate3 === void 0 ? '0, 0, 0' : _ref$translate3,
      _ref$translateEnd = _ref.translateEnd,
      translateEnd = _ref$translateEnd === void 0 ? '0, -4px, 0' : _ref$translateEnd,
      _ref$scaleStart = _ref.scaleStart,
      scaleStart = _ref$scaleStart === void 0 ? '1, 1.1' : _ref$scaleStart,
      _ref$scale = _ref.scale70,
      scale70 = _ref$scale === void 0 ? '1, 1.05' : _ref$scale,
      _ref$scale2 = _ref.scale80,
      scale80 = _ref$scale2 === void 0 ? '1, 0.95' : _ref$scale2,
      _ref$scaleEnd = _ref.scaleEnd,
      scaleEnd = _ref$scaleEnd === void 0 ? '1, 1.02' : _ref$scaleEnd;
  return keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0%,\n  20%,\n  53%,\n  to {\n    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);\n    transform: translate3d(", ");\n  }\n  40%,\n  43% {\n    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);\n    transform: translate3d(", ")  scale(", ");\n  }\n  70% {\n    animation-timing-function: cubic-bezier(0.755, 0.05, 0.855, 0.06);\n    transform: translate3d(", ") scale(", ");\n  }\n  80% {\n    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);\n    transform: translate3d(", ") scale(", ");\n  }\n  90% {\n    transform: translate3d(", ") scale(", ");\n  }\n"])), translateStart, translate40, scaleStart, translate70, scale70, translate80, scale80, translateEnd, scaleEnd);
};

export var bounceAnimation = function bounceAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(bounce(params), params)), {}, {
    transformOrigin: 'center bottom'
  });
};

var flash = function flash(_ref2) {
  var _ref2$opacityStart = _ref2.opacityStart,
      opacityStart = _ref2$opacityStart === void 0 ? 0 : _ref2$opacityStart,
      _ref2$opacityEnd = _ref2.opacityEnd,
      opacityEnd = _ref2$opacityEnd === void 0 ? 1 : _ref2$opacityEnd;
  return keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  0%,\n  50%,\n  to {\n    opacity: ", ";\n  }\n  25%,\n  75% {\n    opacity: ", ";\n  }\n"])), opacityEnd, opacityStart);
};

export var flashAnimation = function flashAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(flash(params), params);
};

var pulse = function pulse(_ref3) {
  var _ref3$scale = _ref3.scale,
      scale = _ref3$scale === void 0 ? '1.05, 1.05, 1.05' : _ref3$scale;
  return keyframes(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  0%,\n  to {\n    transform: scaleX(1);\n  }\n  50% {\n    transform: scale3d(", ");\n  }\n"])), scale);
};

export var pulseAnimation = function pulseAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(pulse(params), params);
};

var rubberBand = function rubberBand(_ref4) {
  var _ref4$scale = _ref4.scale30,
      scale30 = _ref4$scale === void 0 ? '1.25, 0.75, 1' : _ref4$scale,
      _ref4$scale2 = _ref4.scale40,
      scale40 = _ref4$scale2 === void 0 ? '0.75, 1.25, 1' : _ref4$scale2,
      _ref4$scale3 = _ref4.scale50,
      scale50 = _ref4$scale3 === void 0 ? '1.15, 0.85, 1' : _ref4$scale3,
      _ref4$scale4 = _ref4.scale65,
      scale65 = _ref4$scale4 === void 0 ? '0.95, 1.05, 1' : _ref4$scale4,
      _ref4$scale5 = _ref4.scale75,
      scale75 = _ref4$scale5 === void 0 ? '1.05, 0.95, 1' : _ref4$scale5;
  return keyframes(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  0%,\n  to {\n    transform: scaleX(1);\n  }\n  30% {\n    transform: scale3d(", ");\n  }\n  40% {\n    transform: scale3d(", ");\n  }\n  50% {\n    transform: scale3d(", ");\n  }\n  65% {\n    transform: scale3d(", ");\n  }\n  75% {\n    transform: scale3d(", ");\n  }\n"])), scale30, scale40, scale50, scale65, scale75);
};

export var rubberBandAnimation = function rubberBandAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(rubberBand(params), params);
};

var shakeX = function shakeX(_ref5) {
  var _ref5$translateStart = _ref5.translateStart,
      translateStart = _ref5$translateStart === void 0 ? '-10px' : _ref5$translateStart,
      _ref5$translateEnd = _ref5.translateEnd,
      translateEnd = _ref5$translateEnd === void 0 ? '10px' : _ref5$translateEnd;
  return keyframes(_templateObject5 || (_templateObject5 = _taggedTemplateLiteral(["\n  0%,\n  to {\n    transform: translateX(0);\n  }\n  10%,\n  30%,\n  50%,\n  70%,\n  90% {\n    transform: translateX(", ");\n  }\n  20%,\n  40%,\n  60%,\n  80% {\n    transform: translateX(", ");\n  }\n"])), translateStart, translateEnd);
};

export var shakeXAnimation = function shakeXAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(shakeX(params), params);
};

var shakeY = function shakeY(_ref6) {
  var _ref6$translateStart = _ref6.translateStart,
      translateStart = _ref6$translateStart === void 0 ? '-10px' : _ref6$translateStart,
      _ref6$translateEnd = _ref6.translateEnd,
      translateEnd = _ref6$translateEnd === void 0 ? '10px' : _ref6$translateEnd;
  return keyframes(_templateObject6 || (_templateObject6 = _taggedTemplateLiteral(["\n  0%,\n  to {\n    transform: translateY(0);\n  }\n  10%,\n  30%,\n  50%,\n  70%,\n  90% {\n    transform: translateY(", ");\n  }\n  20%,\n  40%,\n  60%,\n  80% {\n    transform: translateY(", ");\n  }\n"])), translateStart, translateEnd);
};

export var shakeYAnimation = function shakeYAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(shakeY(params), params);
};
var handShake = keyframes(_templateObject7 || (_templateObject7 = _taggedTemplateLiteral(["\n  0% {\n    transform: translateX(0);\n  }\n  6.5% {\n    transform: translateX(-6px) rotateY(-9deg);\n  }\n  18.5% {\n    transform: translateX(5px) rotateY(7deg);\n  }\n  31.5% {\n    transform: translateX(-3px) rotateY(-5deg);\n  }\n  43.5% {\n    transform: translateX(2px) rotateY(3deg);\n  }\n  50% {\n    transform: translateX(0);\n  }\n"])));
export var handShakeAnimation = function handShakeAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(handShake, params);
};

var swing = function swing(_ref7) {
  var _ref7$rotate = _ref7.rotate20,
      rotate20 = _ref7$rotate === void 0 ? '15deg' : _ref7$rotate,
      _ref7$rotate2 = _ref7.rotate40,
      rotate40 = _ref7$rotate2 === void 0 ? '-10deg' : _ref7$rotate2,
      _ref7$rotate3 = _ref7.rotate60,
      rotate60 = _ref7$rotate3 === void 0 ? '5deg' : _ref7$rotate3,
      _ref7$rotate4 = _ref7.rotate80,
      rotate80 = _ref7$rotate4 === void 0 ? '-5deg' : _ref7$rotate4;
  return keyframes(_templateObject8 || (_templateObject8 = _taggedTemplateLiteral(["\n  20% {\n    transform: rotate(", ");\n  }\n  40% {\n    transform: rotate(", ");\n  }\n  60% {\n    transform: rotate(", ");\n  }\n  80% {\n    transform: rotate(", ");\n  }\n  to {\n    transform: rotate(0deg);\n  }\n"])), rotate20, rotate40, rotate60, rotate80);
};

export var swingAnimation = function swingAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(swing(params), params);
};

var tada = function tada(_ref8) {
  var _ref8$scale = _ref8.scale20,
      scale20 = _ref8$scale === void 0 ? '0.9, 0.9, 0.9' : _ref8$scale,
      _ref8$rotate = _ref8.rotate20,
      rotate20 = _ref8$rotate === void 0 ? '-3deg' : _ref8$rotate,
      _ref8$scale2 = _ref8.scale30,
      scale30 = _ref8$scale2 === void 0 ? '1.1, 1.1, 1.1' : _ref8$scale2,
      _ref8$rotate2 = _ref8.rotate30,
      rotate30 = _ref8$rotate2 === void 0 ? '3deg' : _ref8$rotate2,
      _ref8$scale3 = _ref8.scale40,
      scale40 = _ref8$scale3 === void 0 ? '1.1, 1.1, 1.1' : _ref8$scale3,
      _ref8$rotate3 = _ref8.rotate40,
      rotate40 = _ref8$rotate3 === void 0 ? '-3deg' : _ref8$rotate3;
  return keyframes(_templateObject9 || (_templateObject9 = _taggedTemplateLiteral(["\n  0%,\n  to {\n    transform: scaleX(1);\n  }\n  10%,\n  20% {\n    transform: scale3d(", ") rotate(", ");\n  }\n  30%,\n  50%,\n  70%,\n  90% {\n    transform: scale3d(", ") rotate(", ");\n  }\n  40%,\n  60%,\n  80% {\n    transform: scale3d(", ") rotate(", ");\n  }\n"])), scale20, rotate20, scale30, rotate30, scale40, rotate40);
};

export var tadaAnimation = function tadaAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(tada(params), params);
};

var wobble = function wobble(_ref9) {
  var _ref9$translate = _ref9.translate15,
      translate15 = _ref9$translate === void 0 ? '-25%, 0, 0' : _ref9$translate,
      _ref9$rotate = _ref9.rotate15,
      rotate15 = _ref9$rotate === void 0 ? '-5deg' : _ref9$rotate,
      _ref9$translate2 = _ref9.translate30,
      translate30 = _ref9$translate2 === void 0 ? '20%, 0, 0' : _ref9$translate2,
      _ref9$rotate2 = _ref9.rotate30,
      rotate30 = _ref9$rotate2 === void 0 ? '3deg' : _ref9$rotate2,
      _ref9$translate3 = _ref9.translate45,
      translate45 = _ref9$translate3 === void 0 ? '-15%, 0, 0' : _ref9$translate3,
      _ref9$rotate3 = _ref9.rotate45,
      rotate45 = _ref9$rotate3 === void 0 ? '-3deg' : _ref9$rotate3,
      _ref9$translate4 = _ref9.translate60,
      translate60 = _ref9$translate4 === void 0 ? '10%, 0, 0' : _ref9$translate4,
      _ref9$rotate4 = _ref9.rotate60,
      rotate60 = _ref9$rotate4 === void 0 ? '2deg' : _ref9$rotate4,
      _ref9$translate5 = _ref9.translate75,
      translate75 = _ref9$translate5 === void 0 ? '-5%, 0, 0' : _ref9$translate5,
      _ref9$rotate5 = _ref9.rotate75,
      rotate75 = _ref9$rotate5 === void 0 ? '-1deg' : _ref9$rotate5;
  return keyframes(_templateObject10 || (_templateObject10 = _taggedTemplateLiteral(["\n  0%,\n  to {\n    transform: translateZ(0);\n  }\n  15% {\n    transform: translate3d(", ") rotate(", ");\n  }\n  30% {\n    transform: translate3d(", ") rotate(", ");\n  }\n  45% {\n    transform: translate3d(", ") rotate(", ");\n  }\n  60% {\n    transform: translate3d(", ") rotate(", ");\n  }\n  75% {\n    transform: translate3d(", ") rotate(", ");\n  }\n"])), translate15, rotate15, translate30, rotate30, translate45, rotate45, translate60, rotate60, translate75, rotate75);
};

export var wobbleAnimation = function wobbleAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(wobble(params), params);
};
var jello = keyframes(_templateObject11 || (_templateObject11 = _taggedTemplateLiteral(["\n  0%,\n  11.1%,\n  to {\n    transform: translateZ(0);\n  }\n  22.2% {\n    transform: skewX(-12.5deg) skewY(-12.5deg);\n  }\n  33.3% {\n    transform: skewX(6.25deg) skewY(6.25deg);\n  }\n  44.4% {\n    transform: skewX(-3.125deg) skewY(-3.125deg);\n  }\n  55.5% {\n    transform: skewX(1.5625deg) skewY(1.5625deg);\n  }\n  66.6% {\n    transform: skewX(-0.78125deg) skewY(-0.78125deg);\n  }\n  77.7% {\n    transform: skewX(0.390625deg) skewY(0.390625deg);\n  }\n  88.8% {\n    transform: skewX(-0.1953125deg) skewY(-0.1953125deg);\n  }\n"])));
export var jelloAnimation = function jelloAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(jello, params)), {}, {
    transformOrigin: 'center'
  });
};

var heartBeat = function heartBeat(_ref10) {
  var _ref10$scale = _ref10.scale14,
      scale14 = _ref10$scale === void 0 ? 1.3 : _ref10$scale,
      _ref10$scale2 = _ref10.scale42,
      scale42 = _ref10$scale2 === void 0 ? 1.3 : _ref10$scale2;
  return keyframes(_templateObject12 || (_templateObject12 = _taggedTemplateLiteral(["\n  0%,\n  28%,\n  70% {\n    transform: scale(1);\n  }\n  14% {\n    transform: scale(", ");\n  }\n  42% {\n    transform: scale(", ");\n  }\n"])), scale14, scale42);
};

export var heartBeatAnimation = function heartBeatAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(heartBeat(params), _objectSpread({
    duration: 1.3
  }, params))), {}, {
    animationTimingFunction: 'ease-in-out'
  });
};

var backInSide = function backInSide(_ref11) {
  var translateStart = _ref11.translateStart,
      _ref11$translateEnd = _ref11.translateEnd,
      translateEnd = _ref11$translateEnd === void 0 ? '0, 0, 0' : _ref11$translateEnd,
      _ref11$scaleStart = _ref11.scaleStart,
      scaleStart = _ref11$scaleStart === void 0 ? '0.7' : _ref11$scaleStart,
      _ref11$scale = _ref11.scale80,
      scale80 = _ref11$scale === void 0 ? '0.7' : _ref11$scale,
      _ref11$scaleEnd = _ref11.scaleEnd,
      scaleEnd = _ref11$scaleEnd === void 0 ? '1' : _ref11$scaleEnd,
      _ref11$opacityStart = _ref11.opacityStart,
      opacityStart = _ref11$opacityStart === void 0 ? '0.7' : _ref11$opacityStart,
      _ref11$opacity = _ref11.opacity80,
      opacity80 = _ref11$opacity === void 0 ? '0.7' : _ref11$opacity,
      _ref11$opacityEnd = _ref11.opacityEnd,
      opacityEnd = _ref11$opacityEnd === void 0 ? '1' : _ref11$opacityEnd;
  return keyframes(_templateObject13 || (_templateObject13 = _taggedTemplateLiteral(["\n  0% {\n    transform: translate3d(", ") scale(", ");\n    opacity: ", ";\n  }\n  80% {\n    transform: translate3d(", ") scale(", ");\n    opacity: ", ";\n  }\n  to {\n    transform: scale(", ");\n    opacity: ", ";\n  }\n"])), translateStart, scaleStart, opacityStart, translateEnd, scale80, opacity80, scaleEnd, opacityEnd);
};

export var backInDownAnimation = function backInDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(backInSide(_objectSpread({
    translateStart: '0, -1200px, 0'
  }, params)), params);
};
export var backInLeftAnimation = function backInLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(backInSide(_objectSpread({
    translateStart: '-2000px, 0, 0'
  }, params)), params);
};
export var backInRightAnimation = function backInRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(backInSide(_objectSpread({
    translateStart: '2000px, 0, 0'
  }, params)), params);
};
export var backInUpAnimation = function backInUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(backInSide(_objectSpread({
    translateStart: '0, 1200px, 0'
  }, params)), params);
};

var backOutSide = function backOutSide(_ref12) {
  var _ref12$translateStart = _ref12.translateStart,
      translateStart = _ref12$translateStart === void 0 ? '0, 0, 0' : _ref12$translateStart,
      translateEnd = _ref12.translateEnd,
      _ref12$scaleStart = _ref12.scaleStart,
      scaleStart = _ref12$scaleStart === void 0 ? '1' : _ref12$scaleStart,
      _ref12$scale = _ref12.scale20,
      scale20 = _ref12$scale === void 0 ? '0.7' : _ref12$scale,
      _ref12$scaleEnd = _ref12.scaleEnd,
      scaleEnd = _ref12$scaleEnd === void 0 ? '0.7' : _ref12$scaleEnd,
      _ref12$opacityStart = _ref12.opacityStart,
      opacityStart = _ref12$opacityStart === void 0 ? '1' : _ref12$opacityStart,
      _ref12$opacity = _ref12.opacity20,
      opacity20 = _ref12$opacity === void 0 ? '0.7' : _ref12$opacity,
      _ref12$opacityEnd = _ref12.opacityEnd,
      opacityEnd = _ref12$opacityEnd === void 0 ? '0.7' : _ref12$opacityEnd;
  return keyframes(_templateObject14 || (_templateObject14 = _taggedTemplateLiteral(["\n  0% {\n    transform: scale(", ");\n    opacity: ", ";\n  }\n  20% {\n    transform: translate3d(", ") scale(", ");\n    opacity: ", ";\n  }\n  to {\n    transform: translate3d(", ") scale(", ");\n    opacity: ", ";\n  }\n"])), scaleStart, opacityStart, translateStart, scale20, opacity20, translateEnd, scaleEnd, opacityEnd);
};

export var backOutDownAnimation = function backOutDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(backOutSide(_objectSpread({
    translateEnd: '0, 700px, 0'
  }, params)), params);
};
export var backOutLeftAnimation = function backOutLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(backOutSide(_objectSpread({
    translateEnd: '-2000px, 0, 0'
  }, params)), params);
};
export var backOutRightAnimation = function backOutRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(backOutSide(_objectSpread({
    translateEnd: '2000px, 0, 0'
  }, params)), params);
};
export var backOutUpAnimation = function backOutUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(backOutSide(_objectSpread({
    translateEnd: '0, -700px, 0'
  }, params)), params);
};

var bounceIn = function bounceIn(_ref13) {
  var _ref13$opacityStart = _ref13.opacityStart,
      opacityStart = _ref13$opacityStart === void 0 ? '0' : _ref13$opacityStart,
      _ref13$opacity = _ref13.opacity60,
      opacity60 = _ref13$opacity === void 0 ? '1' : _ref13$opacity,
      _ref13$opacityEnd = _ref13.opacityEnd,
      opacityEnd = _ref13$opacityEnd === void 0 ? '1' : _ref13$opacityEnd,
      _ref13$scaleStart = _ref13.scaleStart,
      scaleStart = _ref13$scaleStart === void 0 ? '0.3, 0.3, 0.3' : _ref13$scaleStart,
      _ref13$scale = _ref13.scale20,
      scale20 = _ref13$scale === void 0 ? '1.1, 1.1, 1.1' : _ref13$scale,
      _ref13$scale2 = _ref13.scale40,
      scale40 = _ref13$scale2 === void 0 ? '0.9, 0.9, 0.9' : _ref13$scale2,
      _ref13$scale3 = _ref13.scale60,
      scale60 = _ref13$scale3 === void 0 ? '1.03, 1.03, 1.03' : _ref13$scale3,
      _ref13$scale4 = _ref13.scale80,
      scale80 = _ref13$scale4 === void 0 ? '0.97, 0.97, 0.97' : _ref13$scale4,
      _ref13$scaleEnd = _ref13.scaleEnd,
      scaleEnd = _ref13$scaleEnd === void 0 ? '1, 1, 1' : _ref13$scaleEnd;
  return keyframes(_templateObject15 || (_templateObject15 = _taggedTemplateLiteral(["\n  0%,\n  20%,\n  40%,\n  60%,\n  80%,\n  to {\n    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);\n  }\n  0% {\n    opacity: ", ";\n    transform: scale3d(", ");\n  }\n  20% {\n    transform: scale3d(", ");\n  }\n  40% {\n    transform: scale3d(", ");\n  }\n  60% {\n    opacity: ", ";\n    transform: scale3d(", ");\n  }\n  80% {\n    transform: scale3d(", ");\n  }\n  to {\n    opacity: ", ";\n    transform: scale3d(", ");\n  }\n"])), opacityStart, scaleStart, scale20, scale40, opacity60, scale60, scale80, opacityEnd, scaleEnd);
};

export var bounceInAnimation = function bounceInAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceIn(params), _objectSpread({
    duration: 0.75
  }, params));
};

var bounceInSide = function bounceInSide(_ref14) {
  var _ref14$opacityStart = _ref14.opacityStart,
      opacityStart = _ref14$opacityStart === void 0 ? 0 : _ref14$opacityStart,
      _ref14$opacityEnd = _ref14.opacityEnd,
      opacityEnd = _ref14$opacityEnd === void 0 ? 1 : _ref14$opacityEnd,
      translateStart = _ref14.translateStart,
      translate60 = _ref14.translate60,
      translate75 = _ref14.translate75,
      translate90 = _ref14.translate90,
      _ref14$translateEnd = _ref14.translateEnd,
      translateEnd = _ref14$translateEnd === void 0 ? '0, 0, 0' : _ref14$translateEnd,
      scaleStart = _ref14.scaleStart,
      scale60 = _ref14.scale60,
      scale75 = _ref14.scale75,
      scaleEnd = _ref14.scaleEnd;
  return keyframes(_templateObject16 || (_templateObject16 = _taggedTemplateLiteral(["\n  0%,\n  60%,\n  75%,\n  90%,\n  to {\n    animation-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);\n  }\n  0% {\n    opacity: ", ";\n    transform: translate3d(", ") scale(", ");\n  }\n  60% {\n    opacity: ", ";\n    transform: translate3d(", ") scale(", ");\n  }\n  75% {\n    transform: translate3d(", ") scale(", ");\n  }\n  90% {\n    transform: translate3d(", ") scale(", ");\n  }\n  to {\n    transform: translate3d(", ");\n  }\n"])), opacityStart, translateStart, scaleStart, opacityEnd, translate60, scale60, translate75, scale75, translate90, scaleEnd, translateEnd);
};

export var bounceInDownAnimation = function bounceInDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceInSide(_objectSpread({
    translateStart: '0, -3000px, 0',
    translate60: '0, 25px, 0',
    translate75: '0, -10px, 0',
    translate90: '0, 5px, 0',
    scaleStart: '1, 3',
    scale60: '1, 0.9',
    scale75: '1, 0.95',
    scaleEnd: '1, 0.985'
  }, params)), params);
};
export var bounceInLeftAnimation = function bounceInLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceInSide(_objectSpread({
    translateStart: '-3000px, 0, 0',
    translate60: '25px, 0, 0',
    translate75: '-10px, 0, 0',
    translate90: '5px, 0, 0',
    scaleStart: '3, 1',
    scale60: '1, 1',
    scale75: '0.98, 1',
    scaleEnd: '0.995, 1'
  }, params)), params);
};
export var bounceInRightAnimation = function bounceInRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceInSide(_objectSpread({
    translateStart: '3000px, 0, 0',
    translate60: '-25px, 0, 0',
    translate75: '10px, 0, 0',
    translate90: '-5px, 0, 0',
    scaleStart: '3, 1',
    scale60: '1, 1',
    scale75: '0.98, 1',
    scaleEnd: '0.995, 1'
  }, params)), params);
};
export var bounceInUpAnimation = function bounceInUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceInSide(_objectSpread({
    translateStart: '0, 3000px, 0',
    translate60: '0, -20px, 0',
    translate75: '0, 10px, 0',
    translate90: '0, -5px, 0',
    scaleStart: '1, 5',
    scale60: '1, 0.9',
    scale75: '1, 0.95',
    scaleEnd: '1, 0.985'
  }, params)), params);
};

var bounceOut = function bounceOut(_ref15) {
  var _ref15$opacityStart = _ref15.opacityStart,
      opacityStart = _ref15$opacityStart === void 0 ? '1' : _ref15$opacityStart,
      _ref15$opacityEnd = _ref15.opacityEnd,
      opacityEnd = _ref15$opacityEnd === void 0 ? '0' : _ref15$opacityEnd,
      _ref15$scaleStart = _ref15.scaleStart,
      scaleStart = _ref15$scaleStart === void 0 ? '0.9, 0.9, 0.9' : _ref15$scaleStart,
      _ref15$scale = _ref15.scale50,
      scale50 = _ref15$scale === void 0 ? '1.1, 1.1, 1.1' : _ref15$scale,
      _ref15$scaleEnd = _ref15.scaleEnd,
      scaleEnd = _ref15$scaleEnd === void 0 ? '0.3, 0.3, 0.3' : _ref15$scaleEnd;
  return keyframes(_templateObject17 || (_templateObject17 = _taggedTemplateLiteral(["\n  20% {\n    transform: scale3d(", ");\n  }\n  50%,\n  55% {\n    opacity: ", ";\n    transform: scale3d(", ");\n  }\n  to {\n    opacity: ", ";\n    transform: scale3d(", ");\n  }\n"])), scaleStart, opacityStart, scale50, opacityEnd, scaleEnd);
};

export var bounceOutAnimation = function bounceOutAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceOut(params), _objectSpread({
    duration: 0.75
  }, params));
};

var bounceOutSide = function bounceOutSide(_ref16) {
  var _ref16$opacityStart = _ref16.opacityStart,
      opacityStart = _ref16$opacityStart === void 0 ? '1' : _ref16$opacityStart,
      _ref16$opacityEnd = _ref16.opacityEnd,
      opacityEnd = _ref16$opacityEnd === void 0 ? '0' : _ref16$opacityEnd,
      translateStart = _ref16.translateStart,
      translate40 = _ref16.translate40,
      translateEnd = _ref16.translateEnd,
      scaleStart = _ref16.scaleStart,
      scale40 = _ref16.scale40,
      scaleEnd = _ref16.scaleEnd;
  return keyframes(_templateObject18 || (_templateObject18 = _taggedTemplateLiteral(["\n  20% {\n    transform: translate3d(", ") scale(", ");\n  }\n  40%,\n  45% {\n    opacity: ", ";\n    transform: translate3d(", ") scale(", ");\n  }\n  to {\n    opacity: ", ";\n    transform: translate3d(", ") scale(", ");\n  }\n"])), translateStart, scaleStart, opacityStart, translate40, scale40, opacityEnd, translateEnd, scaleEnd);
};

export var bounceOutDownAnimation = function bounceOutDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceOutSide(_objectSpread({
    translateStart: '0, 20px, 0',
    translate40: '0, -40px, 0',
    translateEnd: '0, 2000px, 0',
    scaleStart: '1, 0.985',
    scale40: '1, 0.9',
    scaleEnd: '1, 3'
  }, params)), params);
};
export var bounceOutLeftAnimation = function bounceOutLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceOutSide(_objectSpread({
    translateStart: '-20px, 0, 0',
    translate40: '40px, 0, 0',
    translateEnd: '-2000px, 0, 0',
    scaleStart: '0.985, 1',
    scale40: '0.9, 1',
    scaleEnd: '2, 1'
  }, params)), params);
};
export var bounceOutRightAnimation = function bounceOutRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceOutSide(_objectSpread({
    translateStart: '20px, 0, 0',
    translate40: '-40px, 0, 0',
    translateEnd: '2000px, 0, 0',
    scaleStart: '0.985, 1',
    scale40: '0.9, 1',
    scaleEnd: '2, 1'
  }, params)), params);
};
export var bounceOutUpAnimation = function bounceOutUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(bounceOutSide(_objectSpread({
    translateStart: '0, -20px, 0',
    translate40: '0, 40px, 0',
    translateEnd: '0, -2000px, 0',
    scaleStart: '1, 0.985',
    scale40: '1, 0.9',
    scaleEnd: '1, 3'
  }, params)), params);
};

var fadeIn = function fadeIn(_ref17) {
  var _ref17$opacityStart = _ref17.opacityStart,
      opacityStart = _ref17$opacityStart === void 0 ? 0 : _ref17$opacityStart,
      _ref17$opacityEnd = _ref17.opacityEnd,
      opacityEnd = _ref17$opacityEnd === void 0 ? 1 : _ref17$opacityEnd,
      _ref17$translateStart = _ref17.translateStart,
      translateStart = _ref17$translateStart === void 0 ? '0, 0, 0' : _ref17$translateStart,
      _ref17$translateEnd = _ref17.translateEnd,
      translateEnd = _ref17$translateEnd === void 0 ? '0, 0, 0' : _ref17$translateEnd;
  return keyframes(_templateObject19 || (_templateObject19 = _taggedTemplateLiteral(["\n    0% {\n      opacity: ", ";\n      transform: translate3d(", ");\n    }\n    to {\n      opacity: ", ";\n      transform: translate3d(", ");\n    }\n  "])), opacityStart, translateStart, opacityEnd, translateEnd);
};

export var fadeInAnimation = function fadeInAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeIn(params), params);
};
export var fadeInDownAnimation = function fadeInDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeIn(_objectSpread({
    translateStart: '0, -700px, 0'
  }, params)), params);
};
export var fadeInLeftAnimation = function fadeInLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeIn(_objectSpread({
    translateStart: '-700px, 0, 0'
  }, params)), params);
};
export var fadeInRightAnimation = function fadeInRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeIn(_objectSpread({
    translateStart: '700px, 0, 0'
  }, params)), params);
};
export var fadeInUpAnimation = function fadeInUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeIn(_objectSpread({
    translateStart: '0, 700px, 0'
  }, params)), params);
};
export var fadeInTopLeftAnimation = function fadeInTopLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeIn(_objectSpread({
    translateStart: '-700px, -700px, 0'
  }, params)), params);
};
export var fadeInTopRightAnimation = function fadeInTopRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeIn(_objectSpread({
    translateStart: '700px, -700px, 0'
  }, params)), params);
};
export var fadeInBottomLeftAnimation = function fadeInBottomLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeIn(_objectSpread({
    translateStart: '-700px, 700px, 0'
  }, params)), params);
};
export var fadeInBottomRightAnimation = function fadeInBottomRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeIn(_objectSpread({
    translateStart: '700px, 700px, 0'
  }, params)), params);
};

var fadeOut = function fadeOut(_ref18) {
  var _ref18$opacityStart = _ref18.opacityStart,
      opacityStart = _ref18$opacityStart === void 0 ? 1 : _ref18$opacityStart,
      _ref18$opacityEnd = _ref18.opacityEnd,
      opacityEnd = _ref18$opacityEnd === void 0 ? 0 : _ref18$opacityEnd,
      _ref18$translateStart = _ref18.translateStart,
      translateStart = _ref18$translateStart === void 0 ? '0, 0, 0' : _ref18$translateStart,
      _ref18$translateEnd = _ref18.translateEnd,
      translateEnd = _ref18$translateEnd === void 0 ? '0, 0, 0' : _ref18$translateEnd;
  return keyframes(_templateObject20 || (_templateObject20 = _taggedTemplateLiteral(["\n  0% {\n    opacity: ", ";\n    transform: translate3d(", ");\n  }\n  to {\n    opacity: ", ";\n    transform: translate3d(", ");\n  }\n"])), opacityStart, translateStart, opacityEnd, translateEnd);
};

export var fadeOutAnimation = function fadeOutAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeOut(params), params);
};
export var fadeOutDownAnimation = function fadeOutDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeOut(_objectSpread({
    translateEnd: '0, -700px, 0'
  }, params)), params);
};
export var fadeOutLeftAnimation = function fadeOutLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeOut(_objectSpread({
    translateEnd: '-700px, 0, 0'
  }, params)), params);
};
export var fadeOutRightAnimation = function fadeOutRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeOut(_objectSpread({
    translateEnd: '700px, 0, 0'
  }, params)), params);
};
export var fadeOutUpAnimation = function fadeOutUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeOut(_objectSpread({
    translateEnd: '0, 700px, 0'
  }, params)), params);
};
export var fadeOutTopLeftAnimation = function fadeOutTopLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeOut(_objectSpread({
    translateEnd: '-700px, -700px, 0'
  }, params)), params);
};
export var fadeOutTopRightAnimation = function fadeOutTopRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeOut(_objectSpread({
    translateEnd: '700px, -700px, 0'
  }, params)), params);
};
export var fadeOutBottomLeftAnimation = function fadeOutBottomLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeOut(_objectSpread({
    translateEnd: '700px, 700px, 0'
  }, params)), params);
};
export var fadeOutBottomRightAnimation = function fadeOutBottomRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(fadeOut(_objectSpread({
    translateEnd: '-700px, 700px, 0'
  }, params)), params);
};

var flip = function flip(_ref19) {
  var _ref19$perspective = _ref19.perspective,
      perspective = _ref19$perspective === void 0 ? 500 : _ref19$perspective;
  return keyframes(_templateObject21 || (_templateObject21 = _taggedTemplateLiteral(["\n  0% {\n    transform: perspective(", "px) translateZ(0) rotateY(-1turn);\n    animation-timing-function: ease-out;\n  }\n  40% {\n    transform: perspective(", "px) translateZ(150px) rotateY(-190deg);\n    animation-timing-function: ease-out;\n  }\n  50% {\n    transform: perspective(", "px) translateZ(150px) rotateY(-170deg);\n    animation-timing-function: ease-in;\n  }\n  80% {\n    transform: perspective(", "px) scale3d(0.95, 0.95, 0.95) translateZ(0)\n      rotateY(0deg);\n    animation-timing-function: ease-in;\n  }\n  to {\n    transform: perspective(", "px) translateZ(0) rotateY(0deg);\n    animation-timing-function: ease-in;\n  }\n"])), perspective, perspective, perspective, perspective, perspective);
};

export var flipAnimation = function flipAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(flip(params), params)), {}, {
    backfaceVisibility: 'visible !important'
  });
};

var flipInX = function flipInX(_ref20) {
  var _ref20$perspective = _ref20.perspective,
      perspective = _ref20$perspective === void 0 ? 400 : _ref20$perspective;
  return keyframes(_templateObject22 || (_templateObject22 = _taggedTemplateLiteral(["\n  0% {\n    transform: perspective(", "px) rotateX(90deg);\n    animation-timing-function: ease-in;\n    opacity: 0;\n  }\n  40% {\n    transform: perspective(", "px) rotateX(-20deg);\n    animation-timing-function: ease-in;\n  }\n  60% {\n    transform: perspective(", "px) rotateX(10deg);\n    opacity: 1;\n  }\n  80% {\n    transform: perspective(", "px) rotateX(-5deg);\n  }\n  to {\n    transform: perspective(", "px);\n  }\n"])), perspective, perspective, perspective, perspective, perspective);
};

export var flipInXAnimation = function flipInXAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(flipInX(params), params)), {}, {
    backfaceVisibility: 'visible !important'
  });
};

var flipInY = function flipInY(_ref21) {
  var _ref21$perspective = _ref21.perspective,
      perspective = _ref21$perspective === void 0 ? 400 : _ref21$perspective;
  return keyframes(_templateObject23 || (_templateObject23 = _taggedTemplateLiteral(["\n  0% {\n    transform: perspective(", "px) rotateY(90deg);\n    animation-timing-function: ease-in;\n    opacity: 0;\n  }\n  40% {\n    transform: perspective(", "px) rotateY(-20deg);\n    animation-timing-function: ease-in;\n  }\n  60% {\n    transform: perspective(", "px) rotateY(10deg);\n    opacity: 1;\n  }\n  80% {\n    transform: perspective(", "px) rotateY(-5deg);\n  }\n  to {\n    transform: perspective(", "px);\n  }\n"])), perspective, perspective, perspective, perspective, perspective);
};

export var flipInYAnimation = function flipInYAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(flipInY(params), params)), {}, {
    backfaceVisibility: 'visible !important'
  });
};

var flipOutX = function flipOutX(_ref22) {
  var _ref22$perspective = _ref22.perspective,
      perspective = _ref22$perspective === void 0 ? 400 : _ref22$perspective;
  return keyframes(_templateObject24 || (_templateObject24 = _taggedTemplateLiteral(["\n  0% {\n    transform: perspective(", "px);\n  }\n  30% {\n    transform: perspective(", "px) rotateX(-20deg);\n    opacity: 1;\n  }\n  to {\n    transform: perspective(", "px) rotateX(90deg);\n    opacity: 0;\n  }\n"])), perspective, perspective, perspective);
};

export var flipOutXAnimation = function flipOutXAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(flipOutX(params), _objectSpread({
    duration: 0.75
  }, params))), {}, {
    backfaceVisibility: 'visible !important'
  });
};

var flipOutY = function flipOutY(_ref23) {
  var _ref23$perspective = _ref23.perspective,
      perspective = _ref23$perspective === void 0 ? 400 : _ref23$perspective;
  return keyframes(_templateObject25 || (_templateObject25 = _taggedTemplateLiteral(["\n  0% {\n    transform: perspective(", "px);\n  }\n  30% {\n    transform: perspective(", "px) rotateY(-15deg);\n    opacity: 1;\n  }\n  to {\n    transform: perspective(", "px) rotateY(90deg);\n    opacity: 0;\n  }\n"])), perspective, perspective, perspective);
};

export var flipOutYAnimation = function flipOutYAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(flipOutY(params), _objectSpread({
    duration: 0.75
  }, params))), {}, {
    backfaceVisibility: 'visible !important'
  });
};

var lightSpeedInRight = function lightSpeedInRight(_ref24) {
  var _ref24$opacityStart = _ref24.opacityStart,
      opacityStart = _ref24$opacityStart === void 0 ? 0 : _ref24$opacityStart,
      _ref24$opacityEnd = _ref24.opacityEnd,
      opacityEnd = _ref24$opacityEnd === void 0 ? 1 : _ref24$opacityEnd,
      _ref24$translate = _ref24.translate,
      translate = _ref24$translate === void 0 ? '100%, 0, 0' : _ref24$translate;
  return keyframes(_templateObject26 || (_templateObject26 = _taggedTemplateLiteral(["\n  0% {\n    transform: translate3d(", ") skewX(-30deg);\n    opacity: ", ";\n  }\n  60% {\n    transform: skewX(20deg);\n    opacity: ", ";\n  }\n  80% {\n    transform: skewX(-5deg);\n  }\n  to {\n    transform: translateZ(0);\n  }\n"])), translate, opacityStart, opacityEnd);
};

export var lightSpeedInRightAnimation = function lightSpeedInRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(lightSpeedInRight(params), params)), {}, {
    animationTimingFunction: 'ease-out'
  });
};

var lightSpeedInLeft = function lightSpeedInLeft(_ref25) {
  var _ref25$opacityStart = _ref25.opacityStart,
      opacityStart = _ref25$opacityStart === void 0 ? 0 : _ref25$opacityStart,
      _ref25$opacityEnd = _ref25.opacityEnd,
      opacityEnd = _ref25$opacityEnd === void 0 ? 1 : _ref25$opacityEnd,
      _ref25$translate = _ref25.translate,
      translate = _ref25$translate === void 0 ? '-100%, 0, 0' : _ref25$translate;
  return keyframes(_templateObject27 || (_templateObject27 = _taggedTemplateLiteral(["\n  0% {\n    transform: translate3d(", ") skewX(30deg);\n    opacity: ", ";\n  }\n  60% {\n    transform: skewX(-20deg);\n    opacity: ", ";\n  }\n  80% {\n    transform: skewX(5deg);\n  }\n  to {\n    transform: translateZ(0);\n  }\n"])), translate, opacityStart, opacityEnd);
};

export var lightSpeedInLeftAnimation = function lightSpeedInLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(lightSpeedInLeft(params), params)), {}, {
    animationTimingFunction: 'ease-out'
  });
};

var lightSpeedOutRight = function lightSpeedOutRight(_ref26) {
  var _ref26$opacityStart = _ref26.opacityStart,
      opacityStart = _ref26$opacityStart === void 0 ? 1 : _ref26$opacityStart,
      _ref26$opacityEnd = _ref26.opacityEnd,
      opacityEnd = _ref26$opacityEnd === void 0 ? 0 : _ref26$opacityEnd,
      _ref26$translate = _ref26.translate,
      translate = _ref26$translate === void 0 ? '100%, 0, 0' : _ref26$translate;
  return keyframes(_templateObject28 || (_templateObject28 = _taggedTemplateLiteral(["\n  0% {\n    opacity: ", ";\n  }\n  to {\n    transform: translate3d(", ") skewX(30deg);\n    opacity: ", ";\n  }\n"])), opacityStart, translate, opacityEnd);
};

export var lightSpeedOutRightAnimation = function lightSpeedOutRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(lightSpeedOutRight(params), params)), {}, {
    animationTimingFunction: 'ease-out'
  });
};

var lightSpeedOutLeft = function lightSpeedOutLeft(_ref27) {
  var _ref27$opacityStart = _ref27.opacityStart,
      opacityStart = _ref27$opacityStart === void 0 ? 1 : _ref27$opacityStart,
      _ref27$opacityEnd = _ref27.opacityEnd,
      opacityEnd = _ref27$opacityEnd === void 0 ? 0 : _ref27$opacityEnd,
      _ref27$translate = _ref27.translate,
      translate = _ref27$translate === void 0 ? '-100%, 0, 0' : _ref27$translate;
  return keyframes(_templateObject29 || (_templateObject29 = _taggedTemplateLiteral(["\n  0% {\n    opacity: ", ";\n  }\n  to {\n    transform: translate3d(", ") skewX(-30deg);\n    opacity: ", ";\n  }\n"])), opacityStart, translate, opacityEnd);
};

export var lightSpeedOutLeftAnimation = function lightSpeedOutLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(lightSpeedOutLeft(params), params)), {}, {
    animationTimingFunction: 'ease-out'
  });
};

var rotateIn = function rotateIn(_ref28) {
  var _ref28$opacityStart = _ref28.opacityStart,
      opacityStart = _ref28$opacityStart === void 0 ? 0 : _ref28$opacityStart,
      _ref28$opacityEnd = _ref28.opacityEnd,
      opacityEnd = _ref28$opacityEnd === void 0 ? 1 : _ref28$opacityEnd,
      _ref28$rotate = _ref28.rotate,
      rotate = _ref28$rotate === void 0 ? 200 : _ref28$rotate;
  return keyframes(_templateObject30 || (_templateObject30 = _taggedTemplateLiteral(["\n  0% {\n    transform: rotate(", "deg);\n    opacity: ", ";\n  }\n  to {\n    transform: rotate(0);\n    opacity: ", ";\n  }\n"])), rotate, opacityStart, opacityEnd);
};

export var rotateInAnimation = function rotateInAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'center'
  }, baseAnimationStyle(rotateIn(params), params));
};
export var rotateInDownLeftAnimation = function rotateInDownLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'left bottom'
  }, baseAnimationStyle(rotateIn(_objectSpread({
    rotate: -45
  }, params)), params));
};
export var rotateInDownRightAnimation = function rotateInDownRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'right bottom'
  }, baseAnimationStyle(rotateIn(_objectSpread({
    rotate: 45
  }, params)), params));
};
export var rotateInUpLeftAnimation = function rotateInUpLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'left bottom'
  }, baseAnimationStyle(rotateIn(_objectSpread({
    rotate: 45
  }, params)), params));
};
export var rotateInUpRightAnimation = function rotateInUpRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'right bottom'
  }, baseAnimationStyle(rotateIn(_objectSpread({
    rotate: -45
  }, params)), params));
};

var rotateOut = function rotateOut(_ref29) {
  var _ref29$opacityStart = _ref29.opacityStart,
      opacityStart = _ref29$opacityStart === void 0 ? 1 : _ref29$opacityStart,
      _ref29$opacityEnd = _ref29.opacityEnd,
      opacityEnd = _ref29$opacityEnd === void 0 ? 0 : _ref29$opacityEnd,
      _ref29$rotate = _ref29.rotate,
      rotate = _ref29$rotate === void 0 ? 200 : _ref29$rotate;
  return keyframes(_templateObject31 || (_templateObject31 = _taggedTemplateLiteral(["\n  0% {\n    opacity: ", ";\n  }\n  to {\n    transform: rotate(", "deg);\n    opacity: ", ";\n  }\n"])), opacityStart, rotate, opacityEnd);
};

export var rotateOutAnimation = function rotateOutAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'center'
  }, baseAnimationStyle(rotateOut(params), params));
};
export var rotateOutDownLeftAnimation = function rotateOutDownLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'left bottom'
  }, baseAnimationStyle(rotateOut(_objectSpread({
    rotate: 45
  }, params)), params));
};
export var rotateOutDownRightAnimation = function rotateOutDownRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'right bottom'
  }, baseAnimationStyle(rotateOut(_objectSpread({
    rotate: -45
  }, params)), params));
};
export var rotateOutUpLeftAnimation = function rotateOutUpLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'left bottom'
  }, baseAnimationStyle(rotateOut(_objectSpread({
    rotate: -45
  }, params)), params));
};
export var rotateOutUpRightAnimation = function rotateOutUpRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({
    transformOrigin: 'right bottom'
  }, baseAnimationStyle(rotateOut(_objectSpread({
    rotate: 45
  }, params)), params));
};

var hinge = function hinge(_ref30) {
  var _ref30$opacityStart = _ref30.opacityStart,
      opacityStart = _ref30$opacityStart === void 0 ? 1 : _ref30$opacityStart,
      _ref30$opacityEnd = _ref30.opacityEnd,
      opacityEnd = _ref30$opacityEnd === void 0 ? 0 : _ref30$opacityEnd,
      _ref30$translate = _ref30.translate,
      translate = _ref30$translate === void 0 ? '0, 200px, 0' : _ref30$translate,
      _ref30$rotateStart = _ref30.rotateStart,
      rotateStart = _ref30$rotateStart === void 0 ? 80 : _ref30$rotateStart,
      _ref30$rotateEnd = _ref30.rotateEnd,
      rotateEnd = _ref30$rotateEnd === void 0 ? 60 : _ref30$rotateEnd;
  return keyframes(_templateObject32 || (_templateObject32 = _taggedTemplateLiteral(["\n  0% {\n    animation-timing-function: ease-in-out;\n  }\n  20%,\n  60% {\n    transform: rotate(", "deg);\n    animation-timing-function: ease-in-out;\n  }\n  40%,\n  80% {\n    transform: rotate(", "deg);\n    animation-timing-function: ease-in-out;\n    opacity: ", ";\n  }\n  to {\n    transform: translate3d(", ");\n    opacity: ", ";\n  }\n"])), rotateStart, rotateEnd, opacityStart, translate, opacityEnd);
};

export var hingeAnimation = function hingeAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread(_objectSpread({}, baseAnimationStyle(hinge(params), _objectSpread({
    duration: 2
  }, params))), {}, {
    transformOrigin: 'top left'
  });
};

var jackInTheBox = function jackInTheBox(_ref31) {
  var _ref31$opacityStart = _ref31.opacityStart,
      opacityStart = _ref31$opacityStart === void 0 ? 0 : _ref31$opacityStart,
      _ref31$opacityEnd = _ref31.opacityEnd,
      opacityEnd = _ref31$opacityEnd === void 0 ? 1 : _ref31$opacityEnd,
      _ref31$scaleStart = _ref31.scaleStart,
      scaleStart = _ref31$scaleStart === void 0 ? 0.1 : _ref31$scaleStart,
      _ref31$scaleEnd = _ref31.scaleEnd,
      scaleEnd = _ref31$scaleEnd === void 0 ? 1 : _ref31$scaleEnd;
  return keyframes(_templateObject33 || (_templateObject33 = _taggedTemplateLiteral(["\n  0% {\n    opacity: ", ";\n    transform: scale(", ") rotate(30deg);\n    transform-origin: center bottom;\n  }\n  50% {\n    transform: rotate(-10deg);\n  }\n  70% {\n    transform: rotate(3deg);\n  }\n  to {\n    opacity: ", ";\n    transform: scale(", ");\n  }\n"])), opacityStart, scaleStart, opacityEnd, scaleEnd);
};

export var jackInTheBoxAnimation = function jackInTheBoxAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(jackInTheBox(params), params);
};

var roll = function roll(_ref32) {
  var opacityStart = _ref32.opacityStart,
      opacityEnd = _ref32.opacityEnd,
      translateStart = _ref32.translateStart,
      translateEnd = _ref32.translateEnd,
      rotate = _ref32.rotate;
  return keyframes(_templateObject34 || (_templateObject34 = _taggedTemplateLiteral(["\n  0% {\n    opacity: ", ";\n    transform: translate3d(", ") rotate(", "deg);\n  }\n  to {\n    opacity: ", ";\n    transform: translate3d(", ") rotate(", "deg);\n  }\n"])), opacityStart, translateStart, rotate, opacityEnd, translateEnd, rotate);
};

export var rollInAnimation = function rollInAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(roll(_objectSpread({
    opacityStart: 0,
    opacityEnd: 1,
    translateStart: '-100%, 0, 0',
    rotate: -120
  }, params)), params);
};
export var rollOutAnimation = function rollOutAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(roll(_objectSpread({
    opacityStart: 1,
    opacityEnd: 0,
    translateEnd: '100%, 0, 0',
    rotate: 120
  }, params)), params);
};

var zoomIn = function zoomIn(_ref33) {
  var _ref33$opacityStart = _ref33.opacityStart,
      opacityStart = _ref33$opacityStart === void 0 ? 0 : _ref33$opacityStart,
      _ref33$opacityEnd = _ref33.opacityEnd,
      opacityEnd = _ref33$opacityEnd === void 0 ? 1 : _ref33$opacityEnd,
      _ref33$scale = _ref33.scale,
      scale = _ref33$scale === void 0 ? '0.3, 0.3, 0.3' : _ref33$scale;
  return keyframes(_templateObject35 || (_templateObject35 = _taggedTemplateLiteral(["\n  0% {\n    opacity: ", ";\n    transform: scale3d(", ");\n  }\n  50%,\n  to {\n    opacity: ", ";\n  }\n"])), opacityStart, scale, opacityEnd);
};

export var zoomInAnimation = function zoomInAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(zoomIn(params), params);
};

var zoomOut = function zoomOut(_ref34) {
  var _ref34$opacityStart = _ref34.opacityStart,
      opacityStart = _ref34$opacityStart === void 0 ? 1 : _ref34$opacityStart,
      _ref34$opacityEnd = _ref34.opacityEnd,
      opacityEnd = _ref34$opacityEnd === void 0 ? 0 : _ref34$opacityEnd,
      _ref34$scale = _ref34.scale,
      scale = _ref34$scale === void 0 ? '0.3, 0.3, 0.3' : _ref34$scale;
  return keyframes(_templateObject36 || (_templateObject36 = _taggedTemplateLiteral(["\n  0% {\n    opacity: ", ";\n  }\n  50%,\n  to {\n    opacity: ", ";\n    transform: scale3d(", ");\n  }\n"])), opacityStart, opacityEnd, scale);
};

export var zoomOutAnimation = function zoomOutAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return baseAnimationStyle(zoomOut(params), params);
};

var zoomInSide = function zoomInSide(_ref35) {
  var _ref35$opacityStart = _ref35.opacityStart,
      opacityStart = _ref35$opacityStart === void 0 ? 0 : _ref35$opacityStart,
      _ref35$opacityEnd = _ref35.opacityEnd,
      opacityEnd = _ref35$opacityEnd === void 0 ? 1 : _ref35$opacityEnd,
      _ref35$scaleStart = _ref35.scaleStart,
      scaleStart = _ref35$scaleStart === void 0 ? '0.1, 0.1, 0.1' : _ref35$scaleStart,
      _ref35$scaleEnd = _ref35.scaleEnd,
      scaleEnd = _ref35$scaleEnd === void 0 ? '0.475, 0.475, 0.475' : _ref35$scaleEnd,
      translateStart = _ref35.translateStart,
      translateEnd = _ref35.translateEnd;
  return keyframes(_templateObject37 || (_templateObject37 = _taggedTemplateLiteral(["\n  0% {\n    opacity: ", ";\n    transform: scale3d(", ") translate3d(", ");\n    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);\n  }\n  60% {\n    opacity: ", ";\n    transform: scale3d(", ") translate3d(", ");\n    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);\n  }\n"])), opacityStart, scaleStart, translateStart, opacityEnd, scaleEnd, translateEnd);
};

export var zoomInDownAnimation = function zoomInDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(zoomInSide(_objectSpread({
    translateStart: '0, -1000px, 0',
    translateEnd: '0, 60px, 0'
  }, params)), params));
};
export var zoomInLeftAnimation = function zoomInLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(zoomInSide(_objectSpread({
    translateStart: '-1000px, 0, 0',
    translateEnd: '10px, 0, 0'
  }, params)), params));
};
export var zoomInRightAnimation = function zoomInRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(zoomInSide(_objectSpread({
    translateStart: '1000px, 0, 0',
    translateEnd: '-10px, 0, 0'
  }, params)), params));
};
export var zoomInUpAnimation = function zoomInUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(zoomInSide(_objectSpread({
    translateStart: '0, 1000px, 0',
    translateEnd: '0, -60px, 0'
  }, params)), params));
};

var zoomOutSide = function zoomOutSide(_ref36) {
  var _ref36$opacityStart = _ref36.opacityStart,
      opacityStart = _ref36$opacityStart === void 0 ? 1 : _ref36$opacityStart,
      _ref36$opacityEnd = _ref36.opacityEnd,
      opacityEnd = _ref36$opacityEnd === void 0 ? 0 : _ref36$opacityEnd,
      _ref36$scaleStart = _ref36.scaleStart,
      scaleStart = _ref36$scaleStart === void 0 ? '0.475, 0.475, 0.475' : _ref36$scaleStart,
      _ref36$scaleEnd = _ref36.scaleEnd,
      scaleEnd = _ref36$scaleEnd === void 0 ? '0.1, 0.1, 0.1' : _ref36$scaleEnd,
      translateStart = _ref36.translateStart,
      translateEnd = _ref36.translateEnd;
  return keyframes(_templateObject38 || (_templateObject38 = _taggedTemplateLiteral(["\n  40% {\n    opacity: ", ";\n    transform: scale3d(", ") translate3d(", ");\n    animation-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);\n  }\n  to {\n    opacity: ", ";\n    transform: scale3d(", ") translate3d(", ");\n    animation-timing-function: cubic-bezier(0.175, 0.885, 0.32, 1);\n  }\n"])), opacityStart, scaleStart, translateStart, opacityEnd, scaleEnd, translateEnd);
};

export var zoomOutDownAnimation = function zoomOutDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(zoomOutSide(_objectSpread({
    translateStart: '0, -60px, 0',
    translateEnd: '0, 2000px, 0'
  }, params)), params));
};
export var zoomOutLeftAnimation = function zoomOutLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(zoomOutSide(_objectSpread({
    translateStart: '42px, 0, 0',
    translateEnd: '-2000px, 0, 0'
  }, params)), params));
};
export var zoomOutRightAnimation = function zoomOutRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(zoomOutSide(_objectSpread({
    translateStart: '-42px, 0, 0',
    translateEnd: '2000px, 0, 0'
  }, params)), params));
};
export var zoomOutUpAnimation = function zoomOutUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(zoomOutSide(_objectSpread({
    translateStart: '0, 60px, 0',
    translateEnd: '0, -2000px, 0'
  }, params)), params));
};

var slide = function slide(_ref37) {
  var _ref37$translateStart = _ref37.translateStart,
      translateStart = _ref37$translateStart === void 0 ? '0, 0, 0' : _ref37$translateStart,
      _ref37$translateEnd = _ref37.translateEnd,
      translateEnd = _ref37$translateEnd === void 0 ? '0, 0, 0' : _ref37$translateEnd;
  return keyframes(_templateObject39 || (_templateObject39 = _taggedTemplateLiteral(["\n  0% {\n    transform: translate3d(", ");\n  }\n  to {\n    transform: translate3d(", ");\n  }\n"])), translateStart, translateEnd);
};

export var slideInDownAnimation = function slideInDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(slide(_objectSpread({
    translateStart: '0, -1000px, 0'
  }, params)), params));
};
export var slideInLeftAnimation = function slideInLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(slide(_objectSpread({
    translateStart: '-1000px, 0, 0'
  }, params)), params));
};
export var slideInRightAnimation = function slideInRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(slide(_objectSpread({
    translateStart: '1000px, 0, 0'
  }, params)), params));
};
export var slideInUpAnimation = function slideInUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(slide(_objectSpread({
    translateStart: '0, 1000px, 0'
  }, params)), params));
};
export var slideOutDownAnimation = function slideOutDownAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(slide(_objectSpread({
    translateEnd: '0, 2000px, 0'
  }, params)), params));
};
export var slideOutLeftAnimation = function slideOutLeftAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(slide(_objectSpread({
    translateEnd: '-2000px, 0, 0'
  }, params)), params));
};
export var slideOutRightAnimation = function slideOutRightAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(slide(_objectSpread({
    translateEnd: '2000px, 0, 0'
  }, params)), params));
};
export var slideOutUpAnimation = function slideOutUpAnimation() {
  var params = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  return _objectSpread({}, baseAnimationStyle(slide(_objectSpread({
    translateEnd: '0, -2000px, 0'
  }, params)), params));
};