function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { commonStyles } from '../styles';
export var colorStatus = function colorStatus(props) {
  return function (theme) {
    var _props$type = props.type,
        type = _props$type === void 0 ? 'primary' : _props$type;
    var colorStatusTypeMap = {
      primary: {
        color: theme.white,
        backgroundColor: theme.primary
      },
      info: {
        color: theme.info,
        backgroundColor: theme.infoLight
      },
      blue: {
        color: theme.white,
        backgroundColor: theme.info
      },
      error: {
        color: theme.error,
        backgroundColor: theme.errorLight
      },
      red: {
        color: theme.white,
        backgroundColor: theme.error
      },
      success: {
        color: theme.success,
        backgroundColor: theme.successLight
      },
      green: {
        color: theme.white,
        backgroundColor: theme.success
      },
      warning: {
        color: theme.warning,
        backgroundColor: theme.warningLight
      },
      orange: {
        color: theme.white,
        backgroundColor: theme.warning
      }
    };
    return [colorStatusTypeMap[type], {
      display: 'inline-block',
      fontWeight: 500,
      padding: '4px 12px',
      borderRadius: 10,
      height: '100%',
      fontSize: '1.2rem'
    }].concat(_toConsumableArray(commonStyles(props)));
  };
};