var _templateObject, _templateObject2, _templateObject3, _templateObject4;

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { keyframes } from '@emotion/react';
import { bounceInAnimation, bounceOutAnimation, commonStyles } from '../styles';

var textColorMap = function textColorMap(theme) {
  return {
    primary: theme.white,
    secondary: theme.gray700,
    "default": theme.gray700,
    warning: theme.gray500,
    link: theme.primary
  };
};

var backgroundColorMap = function backgroundColorMap(theme) {
  return {
    primary: theme.primary,
    secondary: theme.white,
    "default": theme.gray50,
    danger: theme.error,
    success: theme.success,
    warning: theme.warning,
    info: theme.info,
    link: 'transparent'
  };
};

var borderColorMap = function borderColorMap(theme) {
  return {
    secondary: theme.gray150,
    link: 'transparent'
  };
};

var hoverBackgroundColorMap = function hoverBackgroundColorMap(theme) {
  return {
    primary: theme.primaryDark,
    secondary: theme.gray50,
    "default": theme.gray100,
    danger: theme.errorDark,
    success: theme.successLight,
    warning: theme.warningLight,
    info: theme.infoLight,
    link: 'transparent'
  };
};

var hoverBorderColorMap = function hoverBorderColorMap(theme) {
  return {
    secondary: theme.gray300,
    link: 'transparent'
  };
};

export var buttonContainer = function buttonContainer(props, isLoading) {
  return function (theme) {
    var _ref, _theme$backColor, _ref2, _theme$hoverBackColor, _ref3, _theme$color, _ref4, _theme$borderColor, _ref5, _theme$hoverBorderCol;

    var _props$type = props.type,
        type = _props$type === void 0 ? 'primary' : _props$type,
        disabled = props.disabled,
        color = props.color,
        borderColor = props.borderColor,
        backColor = props.backColor,
        hoverBackColor = props.hoverBackColor,
        hoverBorderColor = props.hoverBorderColor,
        fullWidth = props.fullWidth,
        small = props.small;
    var backgroundColor = (_ref = (_theme$backColor = theme[backColor]) !== null && _theme$backColor !== void 0 ? _theme$backColor : backgroundColorMap(theme)[type]) !== null && _ref !== void 0 ? _ref : theme.primary;
    var hoverBackgroundColor = (_ref2 = (_theme$hoverBackColor = theme[hoverBackColor]) !== null && _theme$hoverBackColor !== void 0 ? _theme$hoverBackColor : hoverBackgroundColorMap(theme)[type]) !== null && _ref2 !== void 0 ? _ref2 : theme.primaryDark;
    var textColor = (_ref3 = (_theme$color = theme[color]) !== null && _theme$color !== void 0 ? _theme$color : textColorMap(theme)[type]) !== null && _ref3 !== void 0 ? _ref3 : theme.white;
    var borderColorDefault = (_ref4 = (_theme$borderColor = theme[borderColor]) !== null && _theme$borderColor !== void 0 ? _theme$borderColor : borderColorMap(theme)[type]) !== null && _ref4 !== void 0 ? _ref4 : backgroundColor;
    var borderColorHover = (_ref5 = (_theme$hoverBorderCol = theme[hoverBorderColor]) !== null && _theme$hoverBorderCol !== void 0 ? _theme$hoverBorderCol : hoverBorderColorMap(theme)[type]) !== null && _ref5 !== void 0 ? _ref5 : hoverBackgroundColor;
    var isLink = type === 'link';
    return [{
      cursor: disabled ? 'not-allowed' : 'pointer',
      padding: small ? '0.6em 1.6em' : '0.9em 1.6em',
      width: fullWidth ? '100%' : 'auto',
      minHeight: small ? '3.6rem' : '4.4rem',
      display: 'inline-flex',
      justifyContent: 'center',
      alignItems: 'center',
      color: textColor,
      borderRadius: isLink ? 0 : 6,
      lineHeight: 1,
      border: "1px solid ".concat(borderColorDefault),
      opacity: isLoading || disabled ? 0.4 : 1,
      backgroundColor: backgroundColor,
      userSelect: 'none',
      whiteSpace: 'nowrap',
      transition: 'color .15s ease-in-out,background-color .15s ease-in-out,border-color .15s ease-in-out,box-shadow .15s ease-in-out, width .4s ease-in-out',
      '&:hover': _objectSpread({
        borderColor: borderColorHover,
        backgroundColor: hoverBackgroundColor
      }, isLink && {
        borderBottom: "1px solid ".concat(theme.primary)
      }),
      '&:active': {
        outline: "2px solid ".concat(theme.highlight)
      },
      '& > i': {
        marginRight: 6
      }
    }, (disabled || isLoading) && {
      '& *': {
        pointerEvents: 'none'
      }
    }].concat(_toConsumableArray(commonStyles(props)));
  };
};
export var checkButtonContainerBase = function checkButtonContainerBase(hasLabel, props) {
  return [{
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer'
  }, hasLabel && {
    '& > .input-indicator': {
      marginRight: 8,
      flexShrink: 0
    },
    '& > .label': {
      display: 'inline-flex',
      alignItems: 'center',
      whiteSpace: 'nowrap'
    }
  }].concat(_toConsumableArray(commonStyles(props)));
};
var checkButtonAnimation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  50% {\n    transform: scale(1.2);\n  }\n  75% {\n    transform: scale(.9);\n  }\n  100% {\n    transform: scale(1);\n  }\n"])));
export var checkButtonContainer = function checkButtonContainer(hasLabel, props) {
  return function (theme) {
    return [].concat(_toConsumableArray(checkButtonContainerBase(hasLabel, props)), [{
      position: 'relative',
      borderRadius: 6,
      '& .label': {
        whiteSpace: 'nowrap',
        display: 'inline-flex',
        alignItems: 'center'
      },
      '& > .error': {
        color: theme.error,
        marginLeft: 4
      }
    }]);
  };
};
export var checkButtonIndicator = function checkButtonIndicator(isChecked, _ref6) {
  var _ref6$size = _ref6.size,
      size = _ref6$size === void 0 ? 24 : _ref6$size,
      inverse = _ref6.inverse,
      color = _ref6.color,
      disabled = _ref6.disabled;
  return function (theme) {
    var _theme$color2, _theme$color3;

    return [{
      width: size,
      height: size,
      opacity: disabled ? 0.7 : 1,
      flexShrink: 0,
      display: 'block',
      position: 'relative',
      outline: 'none',
      background: theme.white,
      border: 'none',
      cursor: disabled ? 'not-allowed' : 'pointer',
      margin: 0,
      padding: 0,
      borderRadius: 6,
      transition: 'box-shadow .3s',
      boxShadow: "inset 0 0 0 1px ".concat(theme.gray200),
      '&:hover': {
        boxShadow: "inset 0 0 0 2px ".concat(theme.gray150est)
      }
    }, isChecked && {
      boxShadow: "inset 0 0 0 ".concat(inverse ? 2 : size, "px ").concat((_theme$color2 = theme[color]) !== null && _theme$color2 !== void 0 ? _theme$color2 : theme.primary),
      transitionDelay: inverse ? '.4s' : 'unset',
      '&:hover': {
        boxShadow: "inset 0 0 0 ".concat(inverse ? 2 : size, "px ").concat((_theme$color3 = theme[color]) !== null && _theme$color3 !== void 0 ? _theme$color3 : theme.primary)
      }
    }];
  };
};
export var checkButtonSvg = function checkButtonSvg(isChecked, _ref7) {
  var _ref7$size = _ref7.size,
      size = _ref7$size === void 0 ? 24 : _ref7$size,
      inverse = _ref7.inverse,
      color = _ref7.color;
  return function (theme) {
    var _theme$color4;

    return [{
      pointerEvents: 'none',
      fill: 'none',
      strokeWidth: 2,
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      position: 'absolute',
      top: 0,
      left: 0,
      width: size,
      height: size,
      stroke: inverse ? (_theme$color4 = theme[color]) !== null && _theme$color4 !== void 0 ? _theme$color4 : theme.primary : theme.white,
      transform: "scale(".concat(inverse ? 1 : 0, ") translateZ(0)")
    }, inverse && {
      strokeDasharray: '86.12',
      strokeDashoffset: '86.12',
      transition: 'stroke-dasharray .6s, stroke-dashoffset .6s'
    }, isChecked && (inverse ? {
      strokeDasharray: '16.1 86.12',
      strokeDashoffset: '102.22'
    } : {
      animation: "".concat(checkButtonAnimation, " .4s linear forwards .2s")
    })];
  };
};

var radioButtonBorderAnimation = function radioButtonBorderAnimation(_ref8) {
  var startOffset = _ref8.startOffset,
      endOffset = _ref8.endOffset;
  return keyframes(_templateObject2 || (_templateObject2 = _taggedTemplateLiteral(["\n  from {\n    stroke-dashoffset: ", ";\n  }\n  to {\n    stroke-dashoffset: ", ";\n  }\n"])), startOffset, endOffset);
};

export var radioButtonContainer = function radioButtonContainer(isChecked, hasLabel, props) {
  return function (theme) {
    var _theme$color5, _theme$color6;

    var _props$size = props.size,
        size = _props$size === void 0 ? 24 : _props$size,
        color = props.color;
    return [].concat(_toConsumableArray(checkButtonContainerBase(hasLabel, props)), [{
      '& > svg': {
        width: size,
        height: size,
        border: "1px solid ".concat(isChecked ? 'transparent' : theme.gray200),
        borderRadius: '50%',
        transition: 'border-color 0.5s ease'
      },
      '& #border': {
        fill: 'none',
        stroke: isChecked ? (_theme$color5 = theme[color]) !== null && _theme$color5 !== void 0 ? _theme$color5 : theme.primary : theme.gray200,
        strokeWidth: 6,
        strokeLinecap: 'round',
        animation: "".concat(radioButtonBorderAnimation({
          startOffset: isChecked ? size * 6 : 0,
          endOffset: isChecked ? 0 : size * 6
        }), " 0.5s ease forwards"),
        transition: 'stroke 0.5s ease',
        strokeDasharray: "".concat(size * 6)
      },
      '& #dot': _objectSpread({
        fill: (_theme$color6 = theme[color]) !== null && _theme$color6 !== void 0 ? _theme$color6 : theme.primary,
        transformOrigin: '50% 50%'
      }, isChecked ? bounceInAnimation({
        duration: 0.8
      }) : bounceOutAnimation({
        duration: 0.5
      }))
    }]);
  };
};

var switchButtonStartAnimation = function switchButtonStartAnimation(size) {
  return keyframes(_templateObject3 || (_templateObject3 = _taggedTemplateLiteral(["\n  0% {\n    right: ", "px;\n    width: ", ";\n  }\n  25% {\n    right: ", "px;\n    width: calc(100% - ", "px);\n  }\n  50% {\n    right: auto;\n    width: calc(100% - ", "px);\n    left: ", "px;\n  }\n  100% {\n    left: ", "px;\n    width: ", ";\n  }\n"])), size.padding, size.smallWidth, size.padding, size.padding * 2, size.padding * 2, size.padding, size.padding, size.smallWidth);
};

var switchButtonEndAnimation = function switchButtonEndAnimation(size) {
  return keyframes(_templateObject4 || (_templateObject4 = _taggedTemplateLiteral(["\n  0% {\n    left: ", "px;\n    width: ", ";\n  }\n  25% {\n    left: ", "px;\n    width: calc(100% - ", "px);\n  }\n  50% {\n    right: ", "px;\n    width: calc(100% - ", "px);\n    left: auto;\n  }\n  100% {\n    right: ", "px;\n    width: ", ";\n  }\n"])), size.padding, size.smallWidth, size.padding, size.padding * 2, size.padding, size.padding * 2, size.padding, size.smallWidth);
};

export var switchToggle = function switchToggle(size, color, isChecked) {
  return function (theme) {
    var _theme$color7, _theme$color8;

    return {
      cursor: 'pointer',
      width: size.width * 2,
      height: size.width,
      display: 'inline-flex',
      position: 'relative',
      backgroundColor: theme.white,
      textAlign: 'left',
      borderRadius: size.width,
      boxSizing: 'border-box',
      perspective: 300,
      border: "1px solid ".concat(isChecked ? (_theme$color7 = theme[color]) !== null && _theme$color7 !== void 0 ? _theme$color7 : theme.primary : theme.gray200),
      transition: 'border .8s ease-in-out',
      '&:before': {
        content: "''",
        width: size.smallWidth,
        height: size.smallWidth,
        position: 'absolute',
        right: size.padding,
        top: size.padding - 1,
        display: 'inline-flex',
        textAlign: 'center',
        borderRadius: size.smallWidth,
        transition: 'all 1s ease-in-out',
        transformOrigin: '0% 50%',
        transform: 'rotateY(0deg)',
        animation: "".concat(isChecked ? switchButtonEndAnimation(size) : switchButtonStartAnimation(size), " 0.6s ease-in-out forwards"),
        backgroundColor: isChecked ? (_theme$color8 = theme[color]) !== null && _theme$color8 !== void 0 ? _theme$color8 : theme.primary : theme.gray100
      }
    };
  };
};