import _isArray from "lodash/isArray";
import _invert from "lodash/invert";
export var extractBinary = function extractBinary(value, possibleValues) {
  return Object.values(possibleValues).filter(function (el) {
    return (el & value) === Number(el);
  }).map(function (el) {
    return _invert(possibleValues)[el];
  });
};
export var accumulateBinary = function accumulateBinary(value, possibleValues) {
  if (!_isArray(value)) return possibleValues[value];
  return value.reduce(function (acc, val) {
    return acc | possibleValues[val];
  }, 0);
};