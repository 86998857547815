import _uniqueId from "lodash/uniqueId";
import _isFunction from "lodash/isFunction";
import _isString from "lodash/isString";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

export var Types = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning'
};

var prepare = function prepare(notif) {
  return _isString(notif) ? {
    message: notif,
    id: _uniqueId()
  } : _objectSpread(_objectSpread({}, notif), {}, {
    id: _uniqueId()
  });
};

var store = {
  show: function show() {},
  hide: function hide() {}
};
export var showSuccess = function showSuccess(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.SUCCESS
  }));
};
export var showError = function showError(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.ERROR
  }));
};
export var showInfo = function showInfo(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.INFO
  }));
};
export var showWarning = function showWarning(notif) {
  return store.show(_objectSpread(_objectSpread({}, prepare(notif)), {}, {
    type: Types.WARNING
  }));
};
export var hide = function hide(notif) {
  return store.hide(notif === null || notif === void 0 ? void 0 : notif.id);
};
export var notificationsHandler = function notificationsHandler(_ref) {
  var addNotification = _ref.addNotification,
      removeNotification = _ref.removeNotification;
  if (_isFunction(addNotification)) store.show = addNotification;
  if (_isFunction(removeNotification)) store.hide = removeNotification;
};