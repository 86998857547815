import _isFunction from "lodash/isFunction";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { commonStyles } from '../../styles';
export var inputContainer = function inputContainer(props) {
  var horizontal = props.horizontal,
      formId = props.formId,
      validate = props.validate,
      required = props.required;
  var hasValidation = _isFunction(validate) || required;
  return [{
    display: 'flex',
    flexDirection: horizontal ? 'row' : 'column',
    alignItems: 'flex-start',
    justifyContent: horizontal ? 'space-between' : 'flex-start',
    position: 'relative',
    width: '100%',
    outline: 'none',
    marginBottom: !hasValidation && formId ? '1.6rem' : 0
  }].concat(_toConsumableArray(commonStyles(props)));
};
export var inputLabel = function inputLabel(_ref) {
  var horizontal = _ref.horizontal,
      disabled = _ref.disabled;
  return function (theme) {
    return {
      fontSize: '1.3rem',
      fontWeight: 600,
      color: disabled ? theme.gray100 : theme.gray700,
      margin: horizontal ? '0 1.5em 0 0' : '0 0 0.3em 0'
    };
  };
};
export var inputHelpContainer = function inputHelpContainer(theme) {
  return {
    fontSize: '1.15rem',
    lineHeight: 1,
    color: theme.error,
    marginBottom: '1.1em',
    minHeight: '1.2rem',
    fontWeight: 300
  };
};
export var inputHelpContent = {
  display: 'flex',
  alignItems: 'flex-start'
};
export var inputHelpIcon = {
  marginRight: 3,
  width: 'auto',
  height: '1rem',
  maxHeight: '100%'
};