function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { commonStyles } from '../styles';
export default {
  primary: '#EE473A',
  primaryLight: '#FEF5F4',
  primaryDark: '#EB2414',
  greenRaptors: '#14A959',
  gray50: '#F2F2F2',
  gray100: '#E3E3E3',
  gray150: '#D4D4D4',
  gray200: '#B5B5B5',
  gray300: '#979797',
  gray400: '#787878',
  gray500: '#575757',
  gray600: '#2d2d2d',
  gray700: '#252525',
  gray800: '#151515',
  black: '#000000',
  white: '#ffffff',
  info: '#00A0AC',
  infoLight: '#E4F8FD',
  infoDark: '#007179',
  warning: '#FFC453',
  warningLight: '#FFEECD',
  warningDark: '#F6A200',
  success: '#008060',
  successLight: '#D1F0E8',
  successDark: '#005741',
  error: '#D82C0D',
  errorLight: '#F9D4CE',
  errorDark: '#9E2009',
  highlight: '#458FFF',
  boxShadowLight: '0 1px 15px rgb(0 0 0 / 4%), 0 1px 6px rgb(0 0 0 / 4%)',
  boxShadow: '0 4px 20px 1px rgb(0 0 0 / 6%), 0 1px 4px rgb(0 0 0 / 8%)',
  rootFontSize: 10,
  sm: 576,
  md: 786,
  lg: 992,
  xl: 1200,
  xxl: 1400,
  borderRadiusS: 8,
  borderRadiusM: 16,
  borderRadiusL: 24,
  spaceXXS: 4,
  spaceXS: 8,
  spaceS: 12,
  spaceM: 16,
  spaceL: 20,
  spaceXL: 24,
  spaceXXL: 32,
  spaceXXXL: 48,
  spaceXXXXL: 64,
  lineAwesome: true,
  margin: function margin(value) {
    return {
      margin: value
    };
  },
  padding: function padding(value) {
    return {
      padding: value
    };
  },
  flex: function flex(value) {
    return {
      flex: value
    };
  },
  displayFlex: function displayFlex() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        align = _ref.align,
        justify = _ref.justify,
        direction = _ref.direction,
        wrap = _ref.wrap,
        flow = _ref.flow;

    return _objectSpread(_objectSpread(_objectSpread(_objectSpread(_objectSpread({
      display: 'flex'
    }, align && {
      alignItems: align
    }), justify && {
      justifyContent: justify
    }), direction && {
      flexDirection: direction
    }), wrap && {
      flexWrap: wrap
    }), flow && {
      flexFlow: flow
    });
  },
  styles: function styles(properties) {
    return commonStyles(properties);
  }
};