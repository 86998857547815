import _isFunction from "lodash/isFunction";
import _throttle from "lodash/throttle";
import { useEffect } from 'react';
export var useResizeObserver = function useResizeObserver(domNode, callback) {
  var _domNode$current;

  var refNode = (_domNode$current = domNode === null || domNode === void 0 ? void 0 : domNode.current) !== null && _domNode$current !== void 0 ? _domNode$current : domNode;
  useEffect(function () {
    var observer = null;

    if (refNode instanceof Element) {
      observer = new ResizeObserver(_throttle(function (entries) {
        entries.forEach(function (entry) {
          return _isFunction(callback) && callback(entry);
        });
      }, 40));
      observer.observe(refNode);
    }

    return function () {
      if (observer) observer.unobserve(refNode);
    };
  }, [refNode]);
};