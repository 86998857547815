import _isNull from "lodash/isNull";
import _isEmpty from "lodash/isEmpty";
import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

export var isStrNum = function isStrNum(val) {
  return _isString(val) || _isNumber(val);
};
export var isJsonString = function isJsonString(text) {
  if (typeof text !== 'string') return false;

  try {
    JSON.parse(text);
    return true;
  } catch (error) {
    return false;
  }
};
export var concatStrings = function concatStrings() {
  for (var _len = arguments.length, args = new Array(_len), _key = 0; _key < _len; _key++) {
    args[_key] = arguments[_key];
  }

  return args.filter(Boolean).join(' ');
};
export var uuid = function uuid() {
  var pattern = 'xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx';
  return pattern.replace(/x/g, function () {
    return (Math.random() * 16 | 0).toString(16);
  });
};
export var unset = function unset(obj, key) {
  var properties = key.split('.');

  var newObj = _objectSpread({}, obj);

  if (properties.length === 1) {
    delete newObj[properties.pop()];
    return newObj;
  }

  var curProperty = properties.shift();
  newObj[curProperty] = unset(newObj[curProperty], properties);
  if (_isEmpty(newObj[curProperty])) delete newObj[curProperty];
  return newObj;
};
export var parseJson = function parseJson(string, defaultValue) {
  try {
    var parsed = JSON.parse(string);
    return parsed;
  } catch (e) {
    return defaultValue;
  }
};
export var formatShortText = function formatShortText(val, textLength) {
  if (_isNull(val) || val === '' || val === undefined) return '';
  var formattedText = val.replace(/(<([^>]+)>)/gi, '');
  var textLengthVal = textLength !== null && textLength !== void 0 ? textLength : 200;
  return formattedText.length > textLengthVal ? "".concat(formattedText.substring(0, textLengthVal), "...") : formattedText;
};