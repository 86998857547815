function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import PropTypes from 'prop-types';
import { skeletonClass } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var Skeleton = function Skeleton(props) {
  var count = props.count,
      className = props.className;
  var elements = [];

  for (var i = 0; i < count; i++) {
    elements.push(___EmotionJSX("span", _extends({
      key: i,
      css: skeletonClass(props)
    }, className && {
      className: className
    }), "\u200C"));
  }

  return elements;
};

Skeleton.defaultProps = {
  count: 1,
  duration: '1.2'
};
Skeleton.propTypes = {
  count: PropTypes.number,
  duration: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  circle: PropTypes.bool,
  className: PropTypes.string
};
export default Skeleton;