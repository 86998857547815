import _isNumber from "lodash/isNumber";
import _isString from "lodash/isString";
export var commonStyles = function commonStyles(_ref) {
  var displayFlex = _ref.displayFlex,
      display = _ref.display,
      align = _ref.align,
      justify = _ref.justify,
      basis = _ref.basis,
      grow = _ref.grow,
      shrink = _ref.shrink,
      flex = _ref.flex,
      direction = _ref.direction,
      wrap = _ref.wrap,
      flow = _ref.flow,
      minHeight = _ref.minHeight,
      height = _ref.height,
      maxHeight = _ref.maxHeight,
      minWidth = _ref.minWidth,
      width = _ref.width,
      maxWidth = _ref.maxWidth,
      padding = _ref.padding,
      margin = _ref.margin,
      bold = _ref.bold,
      textDecoration = _ref.textDecoration,
      fontSize = _ref.fontSize,
      fontWeight = _ref.fontWeight,
      color = _ref.color,
      textTransform = _ref.textTransform,
      position = _ref.position,
      backgroundColor = _ref.backgroundColor,
      borderRadius = _ref.borderRadius,
      top = _ref.top,
      right = _ref.right,
      bottom = _ref.bottom,
      left = _ref.left;
  return [displayFlex && {
    display: 'flex'
  }, _isString(display) && {
    display: display
  }, _isString(align) && {
    alignItems: align
  }, _isString(justify) && {
    justifyContent: justify
  }, (_isNumber(basis) || _isString(basis)) && {
    flexBasis: basis
  }, (_isNumber(grow) || _isString(grow)) && {
    flexGrow: grow
  }, (_isNumber(shrink) || _isString(shrink)) && {
    flexShrink: shrink
  }, (_isNumber(flex) || _isString(flex)) && {
    flex: flex
  }, _isString(direction) && {
    flexDirection: direction
  }, _isString(wrap) && {
    flexWrap: wrap
  }, _isString(flow) && {
    flexFlow: flow
  }, (_isNumber(minHeight) || _isString(minHeight)) && {
    minHeight: minHeight
  }, (_isNumber(height) || _isString(height)) && {
    height: height
  }, (_isNumber(maxHeight) || _isString(maxHeight)) && {
    maxHeight: maxHeight
  }, (_isNumber(minWidth) || _isString(minWidth)) && {
    minWidth: minWidth
  }, (_isNumber(width) || _isString(width)) && {
    width: width
  }, (_isNumber(maxWidth) || _isString(maxWidth)) && {
    maxWidth: maxWidth
  }, (_isNumber(padding) || _isString(padding)) && {
    padding: padding
  }, (_isNumber(margin) || _isString(margin)) && {
    margin: margin
  }, bold && {
    fontWeight: 600
  }, _isString(textDecoration) && {
    textDecoration: textDecoration
  }, _isNumber(fontWeight) && {
    fontWeight: fontWeight
  }, (_isNumber(fontSize) || _isString(fontSize)) && {
    fontSize: fontSize
  }, _isString(color) && function (theme) {
    var _theme$color;

    return {
      color: "".concat((_theme$color = theme[color]) !== null && _theme$color !== void 0 ? _theme$color : color)
    };
  }, _isString(backgroundColor) && function (theme) {
    var _theme$backgroundColo;

    return {
      backgroundColor: "".concat((_theme$backgroundColo = theme[backgroundColor]) !== null && _theme$backgroundColo !== void 0 ? _theme$backgroundColo : backgroundColor)
    };
  }, _isString(textTransform) && {
    textTransform: textTransform
  }, _isString(position) && {
    position: position
  }, (_isNumber(borderRadius) || _isString(borderRadius)) && {
    borderRadius: borderRadius
  }, (_isNumber(top) || _isString(top)) && {
    top: top
  }, (_isNumber(right) || _isString(right)) && {
    right: right
  }, (_isNumber(bottom) || _isString(bottom)) && {
    bottom: bottom
  }, (_isNumber(left) || _isString(left)) && {
    left: left
  }].filter(Boolean);
};