var _excluded = ["children", "src", "alt", "width", "height", "background", "className"];

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

function _objectWithoutProperties(source, excluded) { if (source == null) return {}; var target = _objectWithoutPropertiesLoose(source, excluded); var key, i; if (Object.getOwnPropertySymbols) { var sourceSymbolKeys = Object.getOwnPropertySymbols(source); for (i = 0; i < sourceSymbolKeys.length; i++) { key = sourceSymbolKeys[i]; if (excluded.indexOf(key) >= 0) continue; if (!Object.prototype.propertyIsEnumerable.call(source, key)) continue; target[key] = source[key]; } } return target; }

function _objectWithoutPropertiesLoose(source, excluded) { if (source == null) return {}; var target = {}; var sourceKeys = Object.keys(source); var key, i; for (i = 0; i < sourceKeys.length; i++) { key = sourceKeys[i]; if (excluded.indexOf(key) >= 0) continue; target[key] = source[key]; } return target; }

import React from 'react';
import PropTypes from 'prop-types';
import { commonStyles } from '../styles';
import { backgroundPicture, imageContainer, child } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var Image = function Image(props) {
  var children = props.children,
      src = props.src,
      alt = props.alt,
      width = props.width,
      height = props.height,
      background = props.background,
      className = props.className,
      restProps = _objectWithoutProperties(props, _excluded);

  return background ? ___EmotionJSX("div", _extends({
    title: alt,
    css: backgroundPicture(props)
  }, className && {
    className: className
  }, restProps), children) : ___EmotionJSX("div", _extends({}, className && {
    className: className
  }, {
    css: commonStyles(props)
  }, restProps), ___EmotionJSX("img", {
    src: src,
    alt: alt,
    width: width || '100%',
    height: height || 'auto',
    css: imageContainer(props)
  }), ___EmotionJSX("div", {
    css: child(props)
  }, children));
};

Image.propTypes = {
  children: PropTypes.any,
  background: PropTypes.bool,
  src: PropTypes.string,
  alt: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string
};
export default Image;