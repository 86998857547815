import _isFunction from "lodash/isFunction";
import _flattenDeep from "lodash/flattenDeep";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _createForOfIteratorHelper(o, allowArrayLike) { var it = typeof Symbol !== "undefined" && o[Symbol.iterator] || o["@@iterator"]; if (!it) { if (Array.isArray(o) || (it = _unsupportedIterableToArray(o)) || allowArrayLike && o && typeof o.length === "number") { if (it) o = it; var i = 0; var F = function F() {}; return { s: F, n: function n() { if (i >= o.length) return { done: true }; return { done: false, value: o[i++] }; }, e: function e(_e) { throw _e; }, f: F }; } throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); } var normalCompletion = true, didErr = false, err; return { s: function s() { it = it.call(o); }, n: function n() { var step = it.next(); normalCompletion = step.done; return step; }, e: function e(_e2) { didErr = true; err = _e2; }, f: function f() { try { if (!normalCompletion && it["return"] != null) it["return"](); } finally { if (didErr) throw err; } } }; }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

var validators = {
  required: {
    regExp: /\S/,
    msg: 'Required field'
  },
  email: {
    regExp: /^$|^(([^<>()\\.,;:\s@"]+(\.[^<>()\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    msg: 'Invalid Email'
  },
  username: {
    regExp: /^$|^[A-Za-z0-9_+\-.!#$'^`~@]{1,25}$/,
    msg: 'Invalid username'
  },
  password: {
    regExp: /^$|^.{6,}$/,
    msg: 'Invalid password'
  },
  phone: {
    regExp: /^$|^[0-9]{10}$/,
    msg: 'Invalid phone number'
  },
  postCode: {
    regExp: /^$|^[0-9]{6}$/,
    msg: 'Invalid post code'
  },
  price: {
    regExp: /^$|^(?:[1-9][0-9]{0,})\.[0-9]{1,2}|[1-9][0-9]*$/,
    msg: 'Invalid number'
  },
  positiveInt: {
    regExp: /^$|^[1-9][0-9]*$/,
    msg: 'Invalid number'
  },
  validHex: {
    regExp: /^$|^#([0-9a-f]{3}|[0-9a-f]{6}|[0-9a-f]{8})$/i,
    msg: 'Invalid HEX color'
  },
  googleMaps: {
    regExp: /^$|^(-?\d+(\.\d+)?),\s*(-?\d+(\.\d+)?)$/,
    msg: 'Invalid latitude/longitude'
  }
};
export default (function () {
  for (var _len = arguments.length, inputValidators = new Array(_len), _key = 0; _key < _len; _key++) {
    inputValidators[_key] = arguments[_key];
  }

  return function (value) {
    var setOfValidators = _flattenDeep(inputValidators);

    var _iterator = _createForOfIteratorHelper(setOfValidators),
        _step;

    try {
      for (_iterator.s(); !(_step = _iterator.n()).done;) {
        var _validator$name;

        var validator = _step.value;
        var validatorName = (_validator$name = validator === null || validator === void 0 ? void 0 : validator.name) !== null && _validator$name !== void 0 ? _validator$name : validator;
        var render = validator === null || validator === void 0 ? void 0 : validator.render;
        var _validators$validator = validators[validatorName],
            regExp = _validators$validator.regExp,
            msg = _validators$validator.msg; // Bypass null/undefined because the regExp api is stupid and if you pass it null/undefined it will convert them to string

        if (!regExp.test("".concat(value !== null && value !== void 0 ? value : ''))) {
          return _objectSpread({
            msg: msg
          }, _isFunction(render) && {
            render: render
          });
        }
      }
    } catch (err) {
      _iterator.e(err);
    } finally {
      _iterator.f();
    }

    return null;
  };
});