import _isArray from "lodash/isArray";
import _isNumber from "lodash/isNumber";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import moment from 'moment';
export var randomString = function randomString(max) {
  var min = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : 1;
  var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  var charLength = characters.length;
  return Array(max).fill().map(function () {
    return characters.charAt(parseInt(Math.random() * (charLength - min) + min, 10));
  }).join('');
};
export var randomDate = function randomDate() {
  var minimumDate = moment().subtract(500, 'days');
  return minimumDate.add(Math.floor(Math.random() * 1000), 'days').format('MM/DD/YYYY');
};
export var mockTableResponse = function mockTableResponse() {
  var key = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 'data';
  var data = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : [];
  var timeout = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 1000;
  return function (options) {
    var _getPromise, _getPromise2;

    var getPromise = function getPromise(resolveData) {
      return new Promise(function (resolve) {
        setTimeout(function () {
          resolve([resolveData]);
        }, timeout);
      });
    };

    if (!_isArray(data)) return getPromise((_getPromise = {}, _defineProperty(_getPromise, key, []), _defineProperty(_getPromise, "total", 0), _getPromise));
    var pageIndex = options.pageIndex,
        pageSize = options.pageSize;

    var filteredData = _toConsumableArray(data);

    if (_isNumber(pageIndex) && _isNumber(pageSize)) filteredData = filteredData.slice(pageIndex * pageSize, (pageIndex + 1) * pageSize);
    return getPromise((_getPromise2 = {}, _defineProperty(_getPromise2, key, filteredData), _defineProperty(_getPromise2, "total", data.length), _getPromise2));
  };
};