import _orderBy from "lodash/orderBy";
import _isEmpty from "lodash/isEmpty";
import _isObject from "lodash/isObject";
import _isArray from "lodash/isArray";
import _isString from "lodash/isString";

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); enumerableOnly && (symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; })), keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = null != arguments[i] ? arguments[i] : {}; i % 2 ? ownKeys(Object(source), !0).forEach(function (key) { _defineProperty(target, key, source[key]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)) : ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } return target; }

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { uuid } from '../../utils';
export var getBase64 = function getBase64(files) {
  return new Promise(function (resolve) {
    var err = null;
    var result = [];

    _toConsumableArray(files).forEach(function (file, ind, arr) {
      var isLast = ind + 1 === arr.length;
      var reader = new FileReader();
      reader.readAsDataURL(file);

      reader.onload = function () {
        result.push({
          __id: uuid(),
          name: file === null || file === void 0 ? void 0 : file.name,
          data: reader.result
        });
        isLast && resolve([result, null]);
      };

      reader.onerror = function (error) {
        err = error;
        isLast && resolve([null, err]);
      };
    });
  });
};
export var checkSize = function checkSize(files, maxSize) {
  return _toConsumableArray(files).every(function (file) {
    return (file === null || file === void 0 ? void 0 : file.size) >= maxSize * 1024 * 1024;
  });
};
export var prepareValue = function prepareValue(initValue) {
  switch (true) {
    case _isString(initValue) && !!(initValue !== null && initValue !== void 0 && initValue.length):
      {
        return {
          __id: uuid(),
          data: initValue,
          name: initValue === null || initValue === void 0 ? void 0 : initValue.split('/').at(-1)
        };
      }

    case _isArray(initValue) && !!(initValue !== null && initValue !== void 0 && initValue.length):
      {
        return _orderBy(initValue === null || initValue === void 0 ? void 0 : initValue.map(function (el) {
          return _objectSpread({
            __id: uuid()
          }, el);
        }), 'order', 'asc');
      }

    case _isObject(initValue) && !_isEmpty(initValue === null || initValue === void 0 ? void 0 : initValue.length):
      {
        return _objectSpread({
          __id: uuid()
        }, initValue);
      }

    default:
      return null;
  }
};