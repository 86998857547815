import _get from "lodash/get";
import _isFunction from "lodash/isFunction";
import _isNil from "lodash/isNil";
import _isEmpty from "lodash/isEmpty";
import _isEqual from "lodash/isEqual";

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _slicedToArray(arr, i) { return _arrayWithHoles(arr) || _iterableToArrayLimit(arr, i) || _unsupportedIterableToArray(arr, i) || _nonIterableRest(); }

function _nonIterableRest() { throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _iterableToArrayLimit(arr, i) { var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"]; if (_i == null) return; var _arr = []; var _n = true; var _d = false; var _s, _e; try { for (_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true) { _arr.push(_s.value); if (i && _arr.length === i) break; } } catch (err) { _d = true; _e = err; } finally { try { if (!_n && _i["return"] != null) _i["return"](); } finally { if (_d) throw _e; } } return _arr; }

function _arrayWithHoles(arr) { if (Array.isArray(arr)) return arr; }

import { useState, useEffect, useImperativeHandle } from 'react';
import { useDeepEffect } from '../../hooks';
import inputValidation from '../inputValidation';
import { outputValue, prepareValue, areEquals as _areEquals, DEFAULT_DISPLAY_KEY, DEFAULT_UNIQUE_KEY, prepareOptions } from './utils';
export var useDropDown = function useDropDown(_ref) {
  var initValue = _ref.value,
      _ref$options = _ref.options,
      initOptions = _ref$options === void 0 ? [] : _ref$options,
      onChange = _ref.onChange,
      onBlur = _ref.onBlur,
      onError = _ref.onError,
      _ref$open = _ref.open,
      open = _ref$open === void 0 ? false : _ref$open,
      _ref$multiSelect = _ref.multiSelect,
      multiSelect = _ref$multiSelect === void 0 ? false : _ref$multiSelect,
      _ref$disabled = _ref.disabled,
      disabled = _ref$disabled === void 0 ? false : _ref$disabled,
      mapValue = _ref.mapValue,
      _ref$required = _ref.required,
      required = _ref$required === void 0 ? false : _ref$required,
      validate = _ref.validate,
      _ref$isTouched = _ref.isTouched,
      isTouchedInit = _ref$isTouched === void 0 ? false : _ref$isTouched,
      _ref$displayKey = _ref.displayKey,
      displayKey = _ref$displayKey === void 0 ? DEFAULT_DISPLAY_KEY : _ref$displayKey,
      _ref$uniqueKey = _ref.uniqueKey,
      uniqueKey = _ref$uniqueKey === void 0 ? DEFAULT_UNIQUE_KEY : _ref$uniqueKey,
      renderSelected = _ref.renderSelected,
      placeholder = _ref.placeholder,
      label = _ref.label,
      dropdownRef = _ref.dropdownRef;
  var options = prepareOptions(initOptions, displayKey);
  var selectedValue = prepareValue(initValue, options, multiSelect, displayKey, uniqueKey);

  var _useState = useState(selectedValue),
      _useState2 = _slicedToArray(_useState, 2),
      selected = _useState2[0],
      setSelected = _useState2[1];

  var _useState3 = useState(open),
      _useState4 = _slicedToArray(_useState3, 2),
      isOpen = _useState4[0],
      setIsOpen = _useState4[1];

  var _useState5 = useState(false),
      _useState6 = _slicedToArray(_useState5, 2),
      error = _useState6[0],
      setError = _useState6[1];

  var _useState7 = useState(isTouchedInit),
      _useState8 = _slicedToArray(_useState7, 2),
      isTouched = _useState8[0],
      setIsTouched = _useState8[1];

  var isDisabled = !(options !== null && options !== void 0 && options.length) || disabled; // Those two references need to be states instead of refs because react-popper does not work with refs

  var _useState9 = useState(),
      _useState10 = _slicedToArray(_useState9, 2),
      selectRef = _useState10[0],
      setSelectRef = _useState10[1];

  var _useState11 = useState(),
      _useState12 = _slicedToArray(_useState11, 2),
      optionsListRef = _useState12[0],
      setOptionsListRef = _useState12[1];

  useImperativeHandle(dropdownRef, function () {
    return {
      changeValue: function changeValue(newValue) {
        return handleSelect(newValue);
      },
      clear: function clear() {
        return handleSelect(null);
      },
      value: selected,
      changeIsTouched: setIsTouched
    };
  });
  useEffect(function () {
    setIsTouched(isTouchedInit);
  }, [isTouchedInit]);
  useEffect(function () {
    isTouched && checkForError(selected);
  }, [isTouched]); // When the options are async and we have value some id, we should have this effect to update selected with matched option

  useDeepEffect(function () {
    setSelected(selectedValue);
  }, [options]);
  useDeepEffect(function () {
    !_areEquals(selected, selectedValue, displayKey, uniqueKey) && setSelected(selectedValue);
  }, [initValue, options]);

  var handleToggle = function handleToggle() {
    return !isDisabled && setIsOpen(function (prev) {
      return !prev;
    });
  };

  var handleKeyDown = function handleKeyDown(e) {
    if (isOpen && e.keyCode === 27) return setIsOpen(false); // Add support for arrows selection and enter selected option
  };

  var handleBlur = function handleBlur(_ref2) {
    var currentTarget = _ref2.currentTarget,
        relatedTarget = _ref2.relatedTarget;

    if (!(currentTarget !== null && currentTarget !== void 0 && currentTarget.contains(relatedTarget)) && !(optionsListRef !== null && optionsListRef !== void 0 && optionsListRef.contains(relatedTarget)) && isOpen && !isDisabled) {
      setIsOpen(false);
      !isTouched && setIsTouched(true);
      checkForError(selected);
      _isFunction(onBlur) && onBlur(hookProperties);
    }
  };

  var handleSelect = function handleSelect(newValue) {
    setIsTouched(true);
    checkForError(newValue);

    if (multiSelect) {
      if (_isNil(newValue)) return changeValue([]);
      var selectedUnique = selected === null || selected === void 0 ? void 0 : selected.filter(function (el) {
        return !_isNil(el);
      });
      var valueIndex = selectedUnique.findIndex(function (e) {
        return _areEquals(e, newValue, displayKey, uniqueKey);
      });
      if (valueIndex === -1) return changeValue([].concat(_toConsumableArray(selectedUnique), [newValue]));
      selectedUnique.splice(valueIndex, 1);
      return changeValue(selectedUnique);
    }

    setIsOpen(false);
    return changeValue(newValue);
  };

  var changeValue = function changeValue(newValue) {
    if (_isEqual(newValue, selected)) return;
    setSelected(newValue);
    _isFunction(onChange) && onChange(outputValue(newValue, displayKey, mapValue));
    isOpen && (selectRef === null || selectRef === void 0 ? void 0 : selectRef.focus());
  };

  var checkForError = function checkForError(newValue) {
    var newError = null; // If validate function is provided check for error

    if (_isFunction(validate)) newError = validate(newValue);else if (required) {
      newError = multiSelect ? _isEmpty(newValue) && {
        msg: 'Required field'
      } : inputValidation('required')(newValue && newValue[uniqueKey]);
    }
    _isFunction(onError) && onError(newError);
    isTouched && setError(newError);
  };

  var defaultPlaceholder = placeholder !== null && placeholder !== void 0 ? placeholder : label && "".concat(label, "...");
  var renderedSelection = _isFunction(renderSelected) ? renderSelected(selected) : null;

  var getOptionValue = function getOptionValue(val) {
    var _val$render, _get2;

    if (!val) return defaultPlaceholder;
    if (val !== null && val !== void 0 && val.render) return (_val$render = val.render(val)) !== null && _val$render !== void 0 ? _val$render : defaultPlaceholder;
    return (_get2 = _get(val, displayKey)) !== null && _get2 !== void 0 ? _get2 : defaultPlaceholder;
  };

  var displayValue = renderedSelection !== null && renderedSelection !== void 0 ? renderedSelection : multiSelect ? defaultPlaceholder : getOptionValue(selected);
  var hookProperties = {
    isOpen: isOpen,
    setIsOpen: setIsOpen,
    isTouched: isTouched,
    setIsTouched: setIsTouched,
    isDisabled: isDisabled,
    selected: selected,
    options: options,
    error: error,
    handleToggle: handleToggle,
    handleKeyDown: handleKeyDown,
    handleBlur: handleBlur,
    handleSelect: handleSelect,
    selectRef: selectRef,
    setSelectRef: setSelectRef,
    optionsListRef: optionsListRef,
    setOptionsListRef: setOptionsListRef,
    displayValue: displayValue,
    areEquals: function areEquals(val) {
      return _areEquals(selected, val, displayKey, uniqueKey);
    }
  };
  return hookProperties;
};