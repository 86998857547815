export var inputField = {
  display: 'none'
};
export var uploadWrap = function uploadWrap(hasValidation, _ref) {
  var multiple = _ref.multiple,
      disabled = _ref.disabled,
      small = _ref.small;
  return function (theme) {
    return [{
      color: theme.gray700,
      borderRadius: '0.5rem',
      display: 'flex',
      alignItems: multiple ? 'flex-start' : 'center',
      justifyContent: multiple ? 'flex-start' : 'space-between',
      flexDirection: multiple ? 'column' : 'row',
      width: '100%',
      marginBottom: hasValidation ? 6 : 0,
      mimHeight: small ? '3.6rem' : '4.4rem',
      '& img': {
        maxWidth: '100%',
        height: 'auto',
        maxHeight: '4.4rem'
      }
    }, disabled && {
      borderColor: theme.gray100,
      color: theme.gray100,
      cursor: 'not-allowed'
    }];
  };
};
export var itemImageWrap = function itemImageWrap(theme) {
  return {
    backgroundColor: theme.white,
    padding: '0.4em',
    borderRadius: 6
  };
};
export var fileInputContent = function fileInputContent(_ref2) {
  var multiple = _ref2.multiple;
  return function (theme) {
    return {
      width: multiple ? '100%' : 'auto',
      padding: multiple ? '0.8em 1em' : 0,
      borderRadius: multiple ? 6 : 0,
      background: multiple ? theme.gray100 : 'transparent',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      marginTop: multiple ? 8 : 0,
      flexDirection: multiple ? 'row-reverse' : 'row'
    };
  };
};
export var fileInputLabel = function fileInputLabel(_ref3) {
  var small = _ref3.small;
  return {
    display: 'flex',
    justifyContent: 'center',
    flex: 1,
    padding: small ? '0.35em 0.85em' : '0.7em 0.85em',
    textOverflow: 'ellipsis'
  };
};