var _templateObject;

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

function _taggedTemplateLiteral(strings, raw) { if (!raw) { raw = strings.slice(0); } return Object.freeze(Object.defineProperties(strings, { raw: { value: Object.freeze(raw) } })); }

import { keyframes } from '@emotion/react';
import { Types } from './Store';
import { commonStyles } from '../styles';

var generateBackColor = function generateBackColor(type) {
  return function (theme) {
    switch (type) {
      case Types.SUCCESS:
        return theme.successLight;

      case Types.ERROR:
        return theme.errorLight;

      case Types.WARNING:
        return theme.warningLight;

      case Types.INFO:
        return theme.infoLight;

      default:
        return theme.successLight;
    }
  };
};

var generateColor = function generateColor(type) {
  return function (theme) {
    switch (type) {
      case Types.SUCCESS:
        return theme.successDark;

      case Types.ERROR:
        return theme.errorDark;

      case Types.WARNING:
        return theme.warningDark;

      case Types.INFO:
        return theme.infoDark;

      default:
        return theme.successDark;
    }
  };
};

var animation = keyframes(_templateObject || (_templateObject = _taggedTemplateLiteral(["\n  0% {\n    transform: translateX(100%);\n  }\n  100% {\n    transform: translateX(0);\n  }\n"])));
export var notification = function notification(type, isHidden, props) {
  return function (theme) {
    return [{
      padding: 16,
      marginBottom: 16,
      color: theme.white,
      backgroundColor: generateBackColor(type)(theme),
      borderRadius: 10,
      transform: isHidden ? 'translateX(calc(100% + 20px))' : 'none',
      transition: 'transform 0.4s ease-in-out',
      animation: "".concat(animation, " 0.4s ease-in-out"),
      cursor: 'pointer',
      '&:last-of-type': {
        marginBottom: 0
      }
    }].concat(_toConsumableArray(commonStyles(props)));
  };
};
export var notifContainer = function notifContainer(type) {
  return function (theme) {
    return {
      display: 'flex',
      alignItems: 'flex-start',
      maxWidth: 500,
      fontSize: '1.2rem',
      color: generateColor(type)(theme),
      '& > svg, & > i': {
        marginRight: 12
      }
    };
  };
};
export var allNotificationsContainer = {
  position: 'fixed',
  top: 20,
  right: 20,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  overflowY: 'auto',
  overflowX: 'hidden',
  maxHeight: 'calc(100% - 40px)'
};
export var notificationIcon = {
  marginTop: 2,
  maxHeight: 20,
  maxWidth: 20
};