import _isEmpty from "lodash/isEmpty";
import _isPlainObject from "lodash/isPlainObject";
import _isNil from "lodash/isNil";
import _isArray from "lodash/isArray";
import _isFunction from "lodash/isFunction";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

import { isStrNum } from '../../utils';
export var DEFAULT_DISPLAY_KEY = 'label';
export var DEFAULT_UNIQUE_KEY = 'value';
export var execute = function execute(value, action) {
  return _isArray(value) ? value.map(action) : action(value);
};
export var getValue = function getValue(value, displayKey, uniqueKey) {
  if (_isNil(value) || isStrNum(value)) return value;
  if (_isNil(value[uniqueKey]) && value._simpleOption) return value[displayKey];
  return uniqueKey ? value[uniqueKey] : value;
};
export var prepareOptions = function prepareOptions(options, displayKey) {
  return _isNil(options) ? options : _isArray(options) ? options.map(function (el) {
    var _ref;

    return isStrNum(el) ? (_ref = {}, _defineProperty(_ref, displayKey, el), _defineProperty(_ref, "_simpleOption", true), _ref) : el;
  }) : [];
};
export var prepareValue = function prepareValue(value, options, multiSelect, displayKey, uniqueKey) {
  var _execute;

  var setValue = function setValue(val) {
    if (isStrNum(val)) {
      var _stringValue;

      var match = options === null || options === void 0 ? void 0 : options.find(function (el) {
        return el[uniqueKey] === val;
      });
      var stringValue = (_stringValue = {}, _defineProperty(_stringValue, displayKey, val), _defineProperty(_stringValue, "_simpleOption", true), _stringValue);
      return match !== null && match !== void 0 ? match : stringValue;
    }

    return val;
  };

  if (multiSelect && !_isArray(value)) {
    var mappedValue = setValue(value);
    return mappedValue ? [mappedValue] : [];
  }

  return (_execute = execute(value, setValue)) !== null && _execute !== void 0 ? _execute : multiSelect ? [] : null;
};
export var outputValue = function outputValue(value, displayKey, mappingFunction) {
  var val = execute(value, function (el) {
    return getValue(el, displayKey);
  });
  return _isFunction(mappingFunction) ? mappingFunction(val) : val;
};
export var areEquals = function areEquals(source, value, displayKey, uniqueKey) {
  var compare = function compare(val1, val2) {
    return getValue(val1, displayKey, uniqueKey) === getValue(val2, displayKey, uniqueKey);
  };

  if (_isEmpty(source) && _isEmpty(value)) return true;
  if (_isPlainObject(source) && _isPlainObject(value)) return compare(source, value);
  if (_isArray(source) && _isArray(value)) return source.length === value.length && source.every(function (sEl) {
    return value.find(function (vEl) {
      return compare(sEl, vEl);
    });
  });
  return false;
};
export var minimumWidth = {
  name: 'minimumWidth',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: function fn(_ref2) {
    var state = _ref2.state;
    state.styles.popper.minWidth = "".concat(state.rects.reference.width, "px");
  },
  effect: function effect(_ref3) {
    var state = _ref3.state;
    state.elements.popper.style.minWidth = "".concat(state.elements.reference.offsetWidth, "px");
  }
};