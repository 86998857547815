import _isFunction from "lodash/isFunction";
import { useEffect } from 'react';
var DEFAULT_ROOT_MARGIN = '0px';
var DEFAULT_THRESHOLD = [0];
export var useIntersectionObserver = function useIntersectionObserver(targetNode, rootNode, callback, options) {
  var _targetNode$current, _rootNode$current;

  var targetRef = (_targetNode$current = targetNode === null || targetNode === void 0 ? void 0 : targetNode.current) !== null && _targetNode$current !== void 0 ? _targetNode$current : targetNode;

  var _ref = options !== null && options !== void 0 ? options : {},
      _ref$rootMargin = _ref.rootMargin,
      rootMargin = _ref$rootMargin === void 0 ? DEFAULT_ROOT_MARGIN : _ref$rootMargin,
      _ref$threshold = _ref.threshold,
      threshold = _ref$threshold === void 0 ? DEFAULT_THRESHOLD : _ref$threshold;

  var rootRef = (_rootNode$current = rootNode === null || rootNode === void 0 ? void 0 : rootNode.current) !== null && _rootNode$current !== void 0 ? _rootNode$current : rootNode;
  useEffect(function () {
    var observer = null;

    if (targetRef instanceof Element && rootRef instanceof Element && _isFunction(callback)) {
      var observeOptions = {
        root: rootRef,
        rootMargin: rootMargin,
        threshold: threshold
      };
      observer = new IntersectionObserver(function (entries) {
        entries.forEach(function (entry) {
          return callback(entry);
        });
      }, observeOptions);
      observer.observe(targetRef);
    }

    return function () {
      if (observer) observer.unobserve(targetRef);
    };
  }, [targetRef, rootRef]);
};