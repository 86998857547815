import _isString from "lodash/isString";
import _isNumber from "lodash/isNumber";

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

function _toConsumableArray(arr) { return _arrayWithoutHoles(arr) || _iterableToArray(arr) || _unsupportedIterableToArray(arr) || _nonIterableSpread(); }

function _nonIterableSpread() { throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method."); }

function _unsupportedIterableToArray(o, minLen) { if (!o) return; if (typeof o === "string") return _arrayLikeToArray(o, minLen); var n = Object.prototype.toString.call(o).slice(8, -1); if (n === "Object" && o.constructor) n = o.constructor.name; if (n === "Map" || n === "Set") return Array.from(o); if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _arrayLikeToArray(o, minLen); }

function _iterableToArray(iter) { if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter); }

function _arrayWithoutHoles(arr) { if (Array.isArray(arr)) return _arrayLikeToArray(arr); }

function _arrayLikeToArray(arr, len) { if (len == null || len > arr.length) len = arr.length; for (var i = 0, arr2 = new Array(len); i < len; i++) { arr2[i] = arr[i]; } return arr2; }

import { commonStyles } from '../styles';
var NUMBER_OF_COLUMNS = 12;
export var containerStyle = function containerStyle(_ref) {
  var gap = _ref.gap,
      _ref$horizontalGap = _ref.horizontalGap,
      horizontalGap = _ref$horizontalGap === void 0 ? 0 : _ref$horizontalGap;
  return {
    paddingLeft: gap !== null && gap !== void 0 ? gap : horizontalGap,
    paddingRight: gap !== null && gap !== void 0 ? gap : horizontalGap
  };
};
export var row = {
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'row'
};
export var rowComp = function rowComp(props) {
  var noWrap = props.noWrap,
      gap = props.gap,
      horizontalGap = props.horizontalGap,
      verticalGap = props.verticalGap;
  return [row, {
    flexDirection: 'row',
    flexWrap: noWrap ? 'no-wrap' : 'wrap'
  }, gap && {
    margin: "-".concat(gap, "px")
  }, horizontalGap && {
    marginLeft: "-".concat(horizontalGap, "px"),
    marginRight: "-".concat(horizontalGap, "px")
  }, verticalGap && {
    marginTop: "-".concat(verticalGap, "px"),
    marginBottom: "-".concat(verticalGap, "px")
  }].concat(_toConsumableArray(commonStyles(props)));
};
var baseCol = {
  flexBasis: '100%',
  maxWidth: '100%',
  flexShrink: 0,
  outline: 'none'
};

var breakPointStyles = function breakPointStyles(type, px, isMax) {
  return _defineProperty({}, "@media (".concat(isMax ? 'max' : 'min', "-width: ").concat(px, "px)"), type === 'auto' ? {
    flex: '0 0 auto',
    maxWidth: 'auto'
  } : _isNumber(type) ? {
    flexBasis: "".concat(100 / (NUMBER_OF_COLUMNS / type), "%"),
    maxWidth: "".concat(100 / (NUMBER_OF_COLUMNS / type), "%")
  } : {
    flex: '1 0 0%'
  });
};

export var col = baseCol;
export var colComp = function colComp(props) {
  var xs = props.xs,
      sm = props.sm,
      md = props.md,
      lg = props.lg,
      xl = props.xl,
      gap = props.gap,
      verticalGap = props.verticalGap,
      horizontalGap = props.horizontalGap;
  return [baseCol, xs && breakPointStyles(xs, 575, true), sm && breakPointStyles(sm, 576), md && breakPointStyles(md, 768), lg && breakPointStyles(lg, 992), xl && breakPointStyles(xl, 1200), (_isNumber(gap) || _isString(gap)) && {
    padding: gap
  }, (_isNumber(verticalGap) || _isString(verticalGap)) && {
    paddingTop: verticalGap,
    paddingBottom: verticalGap
  }, (_isNumber(horizontalGap) || _isString(horizontalGap)) && {
    paddingLeft: horizontalGap,
    paddingRight: horizontalGap
  }].concat(_toConsumableArray(commonStyles(props)));
};