import _isFunction from "lodash/isFunction";
import _isNil from "lodash/isNil";

function _extends() { _extends = Object.assign || function (target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i]; for (var key in source) { if (Object.prototype.hasOwnProperty.call(source, key)) { target[key] = source[key]; } } } return target; }; return _extends.apply(this, arguments); }

import React from 'react';
import PropTypes from 'prop-types';
import BodyOverflow from '../../BodyOverflow';
import { CheckButton } from '../../Button';
import Collapse from '../../Collapse';
import ColorBatch from '../../ColorBatch';
import Input from '../Input';
import { Spinner } from '../../Loader';
import useOptions from './useOptions';
import { DEFAULT_DISPLAY_KEY, DEFAULT_UNIQUE_KEY } from './utils';
import { dropdownSearch, infiniteScroll, dropDownOptionsContainer, dropDownClearAll, dropDownOptionItem, dropDownGroupLabel, dropDownOptionsCollapse, dropDownItemsCountBadge, noResultsContainer } from './styles';
import { jsx as ___EmotionJSX } from "@emotion/react";

var OptionsList = function OptionsList(props) {
  var withSearch = props.withSearch,
      isOpen = props.isOpen,
      small = props.small,
      popperStyle = props.popperStyle,
      popperAttributes = props.popperAttributes,
      optionsListRef = props.optionsListRef,
      setOptionsListRef = props.setOptionsListRef,
      multiSelect = props.multiSelect,
      selected = props.selected,
      handleSelect = props.handleSelect,
      _props$displayKey = props.displayKey,
      displayKey = _props$displayKey === void 0 ? DEFAULT_DISPLAY_KEY : _props$displayKey,
      _props$uniqueKey = props.uniqueKey,
      uniqueKey = _props$uniqueKey === void 0 ? DEFAULT_UNIQUE_KEY : _props$uniqueKey;
  var haveClearSelection = multiSelect && !!(selected !== null && selected !== void 0 && selected.length);
  var placement = optionsListRef === null || optionsListRef === void 0 ? void 0 : optionsListRef.getAttribute('data-popper-placement');

  var getOptionCode = function getOptionCode(val) {
    var _val$uniqueKey;

    return _isNil(val) ? null : (_val$uniqueKey = val[uniqueKey]) !== null && _val$uniqueKey !== void 0 ? _val$uniqueKey : val[displayKey];
  };

  var selectedCodes = multiSelect ? selected.map(getOptionCode) : getOptionCode(selected);

  var isSelectedOption = function isSelectedOption(option) {
    var optionCode = getOptionCode(option);
    return multiSelect ? selectedCodes.indexOf(optionCode) !== -1 : optionCode === selectedCodes;
  };

  var _useOptions = useOptions(props),
      allOptions = _useOptions.allOptions,
      filterResults = _useOptions.filterResults,
      searchInputNodeRef = _useOptions.searchInputNodeRef,
      optionsScrollContainerRef = _useOptions.optionsScrollContainerRef,
      optionsLoadingNodeRef = _useOptions.optionsLoadingNodeRef,
      hasNextPage = _useOptions.hasNextPage;

  if (!isOpen) return null;

  var renderBaseOptionElement = function renderBaseOptionElement(option, ind) {
    var hasRender = _isFunction(option.render);

    var isSelected = isSelectedOption(option);
    var optionsStyles = dropDownOptionItem(!multiSelect && isSelected, small);
    return option.groupLabel ? ___EmotionJSX("h2", {
      key: "item".concat(ind),
      css: dropDownGroupLabel
    }, option.groupLabel) : ___EmotionJSX("div", {
      key: "item".concat(ind),
      role: "menuitem",
      tabIndex: 0,
      onClick: function onClick() {
        return handleSelect(option);
      },
      css: optionsStyles
    }, multiSelect && ___EmotionJSX(CheckButton, {
      value: isSelected,
      size: 20,
      margin: "0 1em 0 0"
    }), hasRender ? option.render(option, optionsStyles) : option[displayKey]);
  };

  var renderList = function renderList() {
    var shouldRenderCollapse = multiSelect && !!(selected !== null && selected !== void 0 && selected.length);
    if (!shouldRenderCollapse) return allOptions.map(renderBaseOptionElement);
    var selectedItems = [],
        unselectedItems = [];
    allOptions.forEach(function (option, ind) {
      var isSelected = isSelectedOption(option);
      var renderedOption = renderBaseOptionElement(option, ind);
      isSelected ? selectedItems.push(renderedOption) : unselectedItems.push(renderedOption);
    });
    return ___EmotionJSX(React.Fragment, null, ___EmotionJSX(Collapse, {
      css: dropDownOptionsCollapse,
      header: ___EmotionJSX(React.Fragment, null, ___EmotionJSX("p", {
        className: "title big"
      }, "Selected"), ___EmotionJSX(ColorBatch, {
        css: dropDownItemsCountBadge(true)
      }, selectedItems.length))
    }, selectedItems), unselectedItems);
  };

  return ___EmotionJSX(BodyOverflow, _extends({
    className: "dropdown-options-overflow",
    ref: setOptionsListRef,
    style: popperStyle,
    zIndex: 3000
  }, popperAttributes), ___EmotionJSX("div", {
    ref: optionsScrollContainerRef,
    role: "presentation",
    onClick: function onClick(event) {
      return event.stopPropagation();
    },
    css: dropDownOptionsContainer(placement)
  }, withSearch && ___EmotionJSX(Input, {
    small: true,
    inputRef: searchInputNodeRef,
    placeholder: "Search",
    leftIcon: {
      iconName: 'las la-search'
    },
    onChange: filterResults,
    css: dropdownSearch
  }), allOptions !== null && allOptions !== void 0 && allOptions.length ? renderList() : ___EmotionJSX("div", {
    css: noResultsContainer
  }, ___EmotionJSX("h5", null, "No results found")), hasNextPage && ___EmotionJSX("div", {
    ref: optionsLoadingNodeRef,
    css: infiniteScroll
  }, ___EmotionJSX(Spinner, {
    size: 65,
    loading: true
  })), haveClearSelection && ___EmotionJSX("div", {
    css: dropDownClearAll(props),
    tabIndex: 0,
    role: "menuitem",
    onClick: function onClick() {
      return handleSelect(null);
    }
  }, "Clear all")));
};

OptionsList.propTypes = {
  options: PropTypes.array,
  selected: PropTypes.oneOfType([PropTypes.array, PropTypes.string, PropTypes.object]),
  multiSelect: PropTypes.bool,
  withSearch: PropTypes.bool,
  isOpen: PropTypes.bool,
  small: PropTypes.bool,
  optionsListRef: PropTypes.any,
  setOptionsListRef: PropTypes.func,
  popperStyle: PropTypes.object,
  popperAttributes: PropTypes.object,
  handleSelect: PropTypes.func,
  selectRef: PropTypes.any,
  displayKey: PropTypes.string,
  uniqueKey: PropTypes.string
};
export default OptionsList;