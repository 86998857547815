export var dropDownContainer = function dropDownContainer(hasValidation, _ref) {
  var disabled = _ref.disabled;
  return {
    position: 'relative',
    cursor: disabled ? 'not-allowed' : 'pointer',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    marginBottom: hasValidation ? 6 : 0
  };
};
export var dropDownSelect = function dropDownSelect(isOpened, isDisabled, hasError, small) {
  return function (theme) {
    return {
      color: isDisabled ? theme.gray150 : theme.gray700,
      border: "1px solid ".concat(isOpened ? theme.highlight : hasError ? theme.error : isDisabled ? theme.gray150 : theme.gray200),
      backgroundColor: hasError ? theme.errorLight : theme.white,
      borderRadius: '0.3rem',
      minHeight: small ? '3.6rem' : '4.4rem',
      padding: small ? '0.35em 0.85em' : '0.7em 0.85em',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between'
    };
  };
};
export var dropDownText = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis'
};
export var dropDownItemsCountBadge = function dropDownItemsCountBadge(isSmall) {
  return {
    fontSize: isSmall ? '1.1rem' : '1.2rem',
    width: isSmall ? '1.7rem' : '2rem',
    height: isSmall ? '1.7rem' : '2rem',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    margin: '0 0.8em 0 0.2em',
    padding: 0
  };
};
export var dropDownOptionsCollapse = function dropDownOptionsCollapse(theme) {
  return {
    borderBottom: "1px solid ".concat(theme.gray100),
    '& .collapse-title': {
      display: 'flex',
      alignItems: 'center',
      '& .title': {
        marginRight: '0.5em'
      }
    }
  };
};
export var dropDownOptionsContainer = function dropDownOptionsContainer(placement) {
  return function (theme) {
    return {
      height: 'auto',
      maxHeight: 320,
      overflowY: 'auto',
      backgroundColor: theme.white,
      transformOrigin: placement === 'bottom' ? 'top' : 'bottom',
      transition: 'transform 400ms ease-in-out',
      cursor: 'default',
      boxShadow: '0 4px 4px rgba(0,0,0,0.3)',
      border: '1px solid #DDDDDD',
      borderRadius: '0.5rem'
    };
  };
};
export var dropDownOptionItem = function dropDownOptionItem(isSelected, small) {
  return function (theme) {
    return {
      backgroundColor: isSelected ? theme.primary : theme.white,
      color: isSelected ? theme.white : theme.gray700,
      display: 'inline-flex',
      alignItems: 'center',
      width: '100%',
      cursor: 'pointer',
      padding: small ? '0.6em' : '1.2rem',
      '&:hover': !isSelected && {
        color: theme.gray700,
        backgroundColor: theme.gray50
      },
      '&:focus': {
        outline: 'none'
      },
      '& i': {
        marginRight: 8
      }
    };
  };
};
export var dropDownGroupLabel = function dropDownGroupLabel(theme) {
  return {
    margin: 0,
    padding: '1.2rem',
    cursor: 'default',
    color: theme.gray200,
    fontSize: 14
  };
};
export var rightIconsContainer = {
  display: 'flex',
  alignItems: 'center'
};
export var dropDownRightIcon = function dropDownRightIcon(isOpened) {
  return function (theme) {
    return {
      fontSize: '1.3rem',
      transform: "rotate(".concat(isOpened ? -180 : 0, "deg)"),
      transition: 'transform 0.35s ease-out',
      cursor: 'pointer',
      display: 'inline-flex',
      color: theme.gray500
    };
  };
};
export var dropDownDeleteIcon = function dropDownDeleteIcon(theme) {
  return {
    display: 'inline-flex',
    fontSize: '1.3rem',
    cursor: 'pointer',
    marginRight: 8,
    color: theme.gray200,
    '&:hover': {
      color: theme.gray500
    }
  };
};
export var dropDownClearAll = function dropDownClearAll(_ref2) {
  var small = _ref2.small;
  return function (theme) {
    return {
      position: 'sticky',
      bottom: 0,
      left: 1,
      right: 1,
      padding: small ? '0 0.6em' : '0 1.2rem',
      color: theme.error,
      backgroundColor: theme.white,
      display: 'flex',
      alignItems: 'center',
      height: small ? '3.6rem' : '4.4rem',
      cursor: 'pointer',
      borderTop: "1px solid ".concat(theme.gray100),
      '&:hover': {
        backgroundColor: theme.gray50
      }
    };
  };
};
export var dropdownSearch = function dropdownSearch(theme) {
  return {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    '& .input': {
      borderRadius: 0,
      border: 'none',
      borderBottom: "1px solid ".concat(theme.gray200)
    }
  };
};
export var noResultsContainer = {
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding: '1.6rem 0.8rem'
};
export var noResultsIcon = function noResultsIcon(theme) {
  return {
    backgroundColor: theme.info,
    borderRadius: '50%',
    padding: 8
  };
};
export var infiniteScroll = {
  padding: '1.6rem',
  display: 'flex',
  justifyContent: 'center'
};