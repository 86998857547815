export var inputField = function inputField(hasValidation, hasError, _ref) {
  var disabled = _ref.disabled,
      small = _ref.small;
  return function (theme) {
    return [{
      width: '100%',
      ' > .form-control': {
        color: theme.gray700,
        border: "1px solid ".concat(hasError ? theme.error : theme.gray200),
        backgroundColor: hasError ? theme.errorLight : theme.white,
        borderRadius: '0.3rem',
        minHeight: small ? '3.6rem' : '4.4rem',
        padding: small ? '0.35em 0.85em' : '0.7em 0.85em',
        width: '100%',
        transition: 'box-shadow .2s ease-in-out, border-color .2s ease-in-out',
        marginBottom: hasValidation ? 6 : 0,
        outline: 'blue'
      }
    }, hasError && {
      '& > .form-control::placeholder': {
        color: theme.gray500
      }
    }, disabled ? {
      ' > .form-control': {
        borderColor: theme.gray150,
        color: theme.gray100,
        cursor: 'not-allowed',
        '&::placeholder': {
          color: theme.gray100
        }
      }
    } : {
      '& > .form-control:focus': {
        borderColor: theme.highlight,
        outline: 'none',
        '&::placeholder': {
          color: theme.gray500
        }
      }
    }];
  };
};